import {ApiError, AuthenticatedApiEndPoint} from '../../helpers/ApiEndPoint';
import {Auth} from '../../helpers/Auth';
import {PracticeEP, PracticesEP} from '../../config/ApiEndpoints';

export default class PracticeApi {

  getPractice = (practiceId, onSuccess, onError, onFinal) => {
    this.setState({isLoading: true});
    const endPoint = new AuthenticatedApiEndPoint(new Auth().getJWT(),
        PracticeEP, {practiceid: practiceId});
    endPoint.get().then(response => {
      if (response.ok) {
        onSuccess(response.data.data);
      }
      else {
        onError(response);
      }
    }).finally(() => {
      onFinal();
    });
  };

  getPractices = (filters, onSuccess, onError, onFinal) => {
    const endPoint = new AuthenticatedApiEndPoint(new Auth().getJWT(),
        PracticesEP);
    let practice = endPoint.get(filters).then(response => {
      if (response.ok) {
        onSuccess(response.data.data, response.data.meta);
      }
      else {
        if (onError) {
          onError(response);
        }
        else {
          ApiError('Could not retrieve practices');
        }
      }
    }).finally(() => {
      if (onFinal) {
        onFinal();
      }
    });
  };
}
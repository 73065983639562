import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {Home} from './layout/Home';
import {MainSite} from './MainSite';
import {PrivateRoute} from '../helpers/PrivateRoute';
import 'semantic-ui-css/semantic.min.css';
import {PracticeSelection} from './practices/PracticeSelection';

export default class App extends Component {
    render() {
        return (
            <Router>
                <Switch>
                    <Route path="/login/:status" component={Home}/>
                    <Route path="/login" component={Home}/>
                    <PrivateRoute strict exact path="/practice-selector"
                                  component={PracticeSelection}/>
                    <PrivateRoute path="/" component={MainSite}/>
                </Switch>
            </Router>
        );
    }
}


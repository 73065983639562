import React, {Component} from 'react';
//import {Form} from 'formsy-semantic-ui-react'
import {Form, Header} from 'semantic-ui-react';
import 'react-datepicker/dist/react-datepicker.css';
import {ClientsEP} from '../../config/ApiEndpoints';
import PropTypes from 'prop-types';
import {apiRequest} from '../../api/psy/ApiRequest';

/**
 *
 */
export class ClientsDropdown extends Component {

  constructor(props) {
    super(props);
    this.state = {
      clients: [], isLoading: false,
    };
  }

  componentDidMount = () => {
    this.loadClients();
  };

  loadClients(searchQuery) {
    this.setState({isLoading: true});
    apiRequest(ClientsEP,
        this.props.practice,
        {'filter[lastname]': searchQuery},
        (data) => this.setState({clients: data},
            () => this.setState({isLoading: false})));
  }

  findClient = (id) => {
    return this.state.clients.find(item => item.id == id);
  };

  /**
   * If we've recieved a non-int id, (e.g My Unit Type) it's a manually entered
   * value this checks if a dropdown value is an id or not
   * @param value
   */
  isSelectionManual = (value) => {
    return isNaN(parseFloat(value)) || !isFinite(value);
  };

  handleClientChange = (e, {value}) => {
    this.props.onClientSelect(value);
  };

  handleInputChange = (e, {searchQuery}) => {
    this.loadClients(searchQuery);
  };

  handleChange = (e, {value}) => {
    if (this.props.onClientSelect) {
      const client = this.findClient(value);
      this.props.onClientSelect(client);
    }
    if (this.props.deselect) {
      this.clear();
    }
  };

  clear = () => {
    this.setState({clear: true});
  };

  render() {

    const options = this.state.clients.map((value, index) => {
      return ({
        key: value.id,
        text: value.firstname + ' ' + value.lastname,
        value: value.id,
      });
    });

    return (
        <Form.Dropdown
            options={options}
            onSearchChange={this.handleInputChange}
            search
            placeholder="Search for client"
            selection
            onChange={this.handleChange}
            value=""
            fluid
            style={{minWidth: '370px'}}
        />

    );

  }
}

const formatOptionLabel = ({value, text, description}) => (
    <Header size="tiny" content={text} subheader={description}/>
);

ClientsDropdown.defaultProps = {

};

ClientsDropdown.propTypes = {
  onClientSelect: PropTypes.func.isRequired, // function that handles client
                                             // dropdown selection
  defaultSelection: PropTypes.any, //selected value of the client dropdown
  width: PropTypes.number,
  name: PropTypes.string.isRequired,
  pracitce: PropTypes.number,
};

import Tooltip from 'react-bootstrap/Tooltip';

export const newPractice = (props) => (
    <Tooltip id="new-practice" {...props}>
      Create New Practice
    </Tooltip>
);

export const newPractitioner = (props) => (
    <Tooltip id="new-practitioner" {...props}>
      Create New Practitioner
    </Tooltip>
);

export const newClient = (props) => (
    <Tooltip id="new-client" {...props}>
      Create New Client
    </Tooltip>
);

export const newUser = (props) => (
    <Tooltip id="new-user" {...props}>
      Create New User
    </Tooltip>
);

export const newLocation = (props) => (
    <Tooltip id="new-location" {...props}>
      Create New Location
    </Tooltip>
);

export const newService = (props) => (
    <Tooltip id="new-service" {...props}>
      Create New Service
    </Tooltip>
);

export const newAppointment = (props) => (
    <Tooltip id="new-appointment" {...props}>
      Create New Appointment
    </Tooltip>
);

export const editDetails = (props) => (
    <Tooltip id="edit-details" {...props}>
      Edit Details
    </Tooltip>
);

export const deleteRecord = (props) => (
    <Tooltip id="edit-record" {...props}>
      Delete Record
    </Tooltip>
);

export const showProfile = (props) => (
    <Tooltip id="show-Profile" {...props}>
      Show Profile
    </Tooltip>
);

export const copyRecord = (props) => (
    <Tooltip id="edit-details" {...props}>
      Copy Profile
    </Tooltip>
);

export const showAppointments = (props) => (
    <Tooltip id="show-appointments" {...props}>
      Show Appointments
    </Tooltip>
);

export const userVerified = (props) => (
    <Tooltip id="user-Verified" {...props}>
      User Email Verified?
    </Tooltip>
);

export const userStatus = (props) => (
    <Tooltip id="user-Status" {...props}>
      User Approved?
    </Tooltip>
);
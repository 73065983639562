import React, {Component} from 'react';
import {PracticesEP} from '../../config/ApiEndpoints';
import {Auth} from '../../helpers/Auth.js';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
//import Pagination from 'react-bootstrap/Pagination'
import Table from 'react-bootstrap/Table';
import {Modal, Pagination} from 'semantic-ui-react';
import {PracticeEdit} from './PracticeEdit';
import {PracticeCopy} from './PracticeCopy';
import {PracticeCreate} from './PracticeCreate';
import {PracticeDeleter} from './PracticeDeleter';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import '../../styles/output.css';
import {
    copyRecord,
    deleteRecord,
    editDetails,
    newPractice,
    showAppointments,
    showProfile,
    userStatus,
    userVerified,
} from '../helpers/popovers/PopOvers';
import 'tippy.js/dist/tippy.css';
import SearchBar from '../../components/helpers/searchbox/SearchBar';
import {apiRequest} from '../../api/psy/ApiRequest';
import {getSessionPracticeId} from '../../helpers/Session';

export class PracticeList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            practiceData: [],
            isLoading: false,
            editPracticeModal: false,
            deletePractice: undefined,
            syncingPractice: false,
            activePage: 1, 
            totalPages: 1, 
            practice_name: undefined,
            sort: null,
            filter: null
        };
        this.filterDebouncer = AwesomeDebouncePromise(this.loadPractices, 200);
    }

    componentWillMount() {
        this.loadPractices();
    }

    loadPractices = () => {
        this.setState({isLoading: true});

        apiRequest(PracticesEP, null, {
            ...(this.state.filter ? {} : {page: this.state.activePage}),
            sort: 'practice_name',
            'filter[practice_name]': this.state.filter,
            'filter[id]': getSessionPracticeId(),
        }, (data, meta) => {
            this.setState({practiceData: data, totalPages: meta.last_page});
        }, () => this.setState({isLoading: false}));

    };

    handlePaginationChange = (e, {activePage}) => {
        this.setState({activePage}, this.loadPractices)
    };

    handleFilterChange = async (e) => {
        const {value} = e.target
        this.setState({filter: value}, () => {
            this.filterDebouncer()
            //this.loadClients()
        });
    }

    onPracticeAdded = () => {
        this.onModalClose();
        this.loadPractices();

    };

    onPracticeDeleted = (data) => {
        this.loadPractices();
        this.setState({deletePractice: null});
    };

    onPracticeSyncronised = () => {
        this.setState({syncronisePractice: null});
    };

    handleDeletePractice = (data) => {
        this.setState({deletePractice: data.id});
    };

    handleSyncronisePractice = (data) => {
        this.setState({syncronisePractice: data.id});
    };

    handleEditPractice = (data) => {
        this.setState({editPractice: data, editPracticeModal: true});
    };

    onModalClick = () => this.setState({editPracticeModal: true, editPractice: false});
    onModalClose = () => this.setState({editPracticeModal: false, editPractice: false});

    render() {

        const practiceData = this.state.practiceData;
        const auth = new Auth();
        return (

            <Container fluid
                       className="p-2 min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
                <Row>
                    <Col lg={8} xs={4} md={8}>
                        <h1 className="text-2xl p-2">Practices</h1>
                    </Col>

                    <Col lg={4} xs={4} md={4}>
                        <OverlayTrigger
                            placement="top"
                            delay={{show: 250, hide: 400}}
                            overlay={newPractice}
                        >
                            <div class="float-right">
                                <Button
                                    onClick={() => this.handleCreatePractice()}
                                    variant="secondary">
                                    New Practice
                                </Button>
                            </div>
                        </OverlayTrigger>
                    </Col>

                </Row>
                <Row>
                    {this.state.isLoading === true &&
                    <Col lg="auto" xs={2} md={4}>

                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>

                    </Col>
                    }
                    <Col lg={8} xs={12} md={12}>
                        {this.state.totalPages > 1 &&
                        <Pagination
                            defaultActivePage={1}
                            totalPages={this.state.totalPages}
                            onPageChange={this.handlePaginationChange}
                            pointing
                            secondary
                        />
                        }
                    </Col>
                </Row>

                <Row>
                    <Col lg={12} xs="auto" md="auto">
                        <Table responsive
                               className="table-auto w-full overflow-auto  m-1 divide-y divide-purple-400">
                            <thead className="bg-gray-50">
                            <tr>
                                <th
                                    scope="col"
                                    className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >practice
                                </th>
                                <th
                                    scope="col"
                                    className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >Owner
                                </th>
                                <th
                                    scope="col"
                                    className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >User Status
                                </th>
                                <th colspan={2}
                                    scope="col"
                                    className="px-2 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >Actions
                                </th>
                            </tr>
                            </thead>
                            <tbody
                                className="bg-white divide-y divide-gray-200">
                            <tr>
                                <td colSpan={4}>
                                    <SearchBar
                                        handleFilterChange={this.handleFilterChange}
                                        filter={this.state.filter}
                                    />
                                </td>
                            </tr>
                            {practiceData.map((data) => {
                                return <tr key={data.id}>
                                    <td className="px-2 py-3 whitespace-nowrap">
                                        <Container className="items-center">
                                            <Row>
                                                <Col>
                                                    <Row>
                                                        <Col
                                                            className="p-1 text-sm font-medium text-gray-900 fas fa-city">
                                                            &nbsp;{data.practice_name}
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col
                                                            className="p-1 text-sm text-gray-500 inline-flex fas fa-at">
                                                            &nbsp;&nbsp;{data.owner.email}
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col
                                                            className="p-1 text-sm text-gray-500 inline-flex fas fa-phone">
                                                            &nbsp;&nbsp;{data.owner.mobile}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </td>
                                    <td className="px-2 py-4 whitespace-nowrap items-center text-sm font-medium text-gray-900">
                                        {data.owner.firstname} {data.owner.lastname}
                                    </td>
                                    <td className="px-2 py-4 whitespace-nowrap">
                                        <Container
                                            className="text-sm font-medium text-gray-900">
                                            {data.owner.verified > false &&
                                            <OverlayTrigger
                                                placement="top"
                                                delay={{show: 250, hide: 400}}
                                                overlay={userVerified}
                                            >
                                                    <span
                                                        className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-200 text-green-800">
                                                        Verified
                                                    </span>
                                            </OverlayTrigger>}

                                            {data.owner.verified < true &&
                                            <OverlayTrigger
                                                placement="top"
                                                delay={{show: 250, hide: 400}}
                                                overlay={userVerified}
                                            >
                                                    <span
                                                        className="px-3 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-200 text-green-800">
                                                        Not Verified
                                                    </span>
                                            </OverlayTrigger>}
                                            <br></br>
                                            {data.owner.approved > false &&
                                            <OverlayTrigger
                                                placement="top"
                                                delay={{show: 250, hide: 400}}
                                                overlay={userStatus}
                                            >
                                                    <span
                                                        className="px-5 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-200 text-green-800">
                                                        Approved
                                                    </span>
                                            </OverlayTrigger>}

                                            {data.owner.approved < true &&
                                            <OverlayTrigger
                                                placement="top"
                                                delay={{show: 250, hide: 400}}
                                                overlay={userStatus}
                                            >
                                                <span
                                                    className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-200 text-green-800">
                                                    Not Approved
                                                </span>
                                            </OverlayTrigger>}
                                        </Container>
                                    </td>
                                    <td className="px-1 py-4 whitespace-nowrap">
                                        <Row>
                                            <Col className="p-1">
                                                <OverlayTrigger
                                                    placement="top"
                                                    delay={{
                                                        show: 250,
                                                        hide: 400,
                                                    }}
                                                    overlay={showAppointments}
                                                >
                                                    <a onClick={() => this.handleBookPractice(
                                                        data)}
                                                       className="text-sm font-medium text-orange-600 cursor-pointer text-decoration-none  d-flex fas fa-calendar-alt">
                                                        <span>&nbsp;Appointments</span>
                                                    </a>
                                                </OverlayTrigger>
                                            </Col>
                                            <Col className="p-1">
                                                <OverlayTrigger
                                                    placement="top"
                                                    delay={{
                                                        show: 250,
                                                        hide: 400,
                                                    }}
                                                    overlay={deleteRecord}
                                                >
                                                    <a onClick={() => this.handleDeletePractice(
                                                        data)}
                                                       className="text-sm font-medium text-red-800 cursor-pointer text-decoration-none  d-flex fas fa-trash">
                                                        <span>&nbsp;Delete Practice</span>
                                                    </a>
                                                </OverlayTrigger>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="p-1">
                                                <OverlayTrigger
                                                    placement="top"
                                                    delay={{
                                                        show: 250,
                                                        hide: 400,
                                                    }}
                                                    overlay={showProfile}
                                                >
                                                    <a onClick={() => this.handleShowPractice(
                                                        data)}
                                                       className="text-sm font-medium text-green-900 cursor-pointer text-decoration-none  d-flex fas fa-user">
                                                        <span>&nbsp;Show Profile</span>
                                                    </a>
                                                </OverlayTrigger>
                                            </Col>
                                            <Col className="p-1">
                                                <OverlayTrigger
                                                    placement="top"
                                                    delay={{
                                                        show: 250,
                                                        hide: 400,
                                                    }}
                                                    overlay={copyRecord}
                                                >
                                                    <a onClick={() => this.handleCopyPractice(
                                                        data)}
                                                       className="text-sm font-medium text-gray-900 cursor-pointer text-decoration-none d-flex fas fa-calendar-alt">
                                                        <span>&nbsp;Copy Practice</span>
                                                    </a>
                                                </OverlayTrigger>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="p-1">
                                                <OverlayTrigger
                                                    placement="top"
                                                    delay={{
                                                        show: 250,
                                                        hide: 400,
                                                    }}
                                                    overlay={editDetails}
                                                >
                                                    <a onClick={() => this.handleEditPractice(
                                                        data)}
                                                       className="text-sm font-medium text-decoration-none text-bluegray-700 cursor-pointer d-flex fas fa-calendar-alt">
                                                        <span>&nbsp;Edit Profile</span>
                                                    </a>
                                                </OverlayTrigger>
                                            </Col>
                                        </Row>
                                    </td>
                                </tr>;
                            })
                            }
                            </tbody>
                        </Table>
                        <div
                            class="py-2 align-middle page-wrapper inline-block min-w-full sm:px-6 lg:px-8">
                            <div
                                className="overflow-auto border-b border-gray-200 sm:rounded-lg">
                                {this.state.deletePractice &&
                                <PracticeDeleter
                                    practiceId={this.state.deletePractice}
                                    open={Number.isInteger(
                                        this.state.deletePractice)}
                                    onCancel={() => this.setState(
                                        {deletePractice: null})}
                                    onDeletePractice={this.onPracticeDeleted}/>
                                }

                                {this.state.showPractice &&
                                this.props.history.push('/practiceP',
                                    {practiceId: this.state.showPractice})
                //<PracticeProfile practiceId={this.state.showPractice} open={(this.state.showPractice)}
                               // onCancel={() => this.setState({showPractice: null})}/>
                }

                {this.state.editPracticeModal &&
                <Modal open={this.state.editPracticeModal}
                       closeIcon
                       size='medium'
                       //style={{height: 400}}
                       //dimmer='blurring'
                       //style={{ marginBottom: 10, height: 400 }}
                       onClose={this.onModalClose}
                >
                    <Modal.Header >Edit Practice</Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <PracticeEdit data={this.state.editPractice} onCancel={this.onModalClose}
                                         onPracticeSaved={this.onPracticeAdded}/>
                        </Modal.Description>
                    </Modal.Content>
                </Modal>
                }

                {this.state.copyPracticeModal &&
                <Modal open={this.state.copyPracticeModal}
                       closeIcon
                       size='tiny'
                       style={{height: 400}}
                       onClose={this.onCopyModalClose}
                >
                    <Modal.Header>Copy Practice</Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <PracticeCopy data={this.state.copyPractice} onCancel={this.onCopyModalClose}
                                         onPracticeSaved={this.onPracticeAdded}/>
                        </Modal.Description>
                    </Modal.Content>
                </Modal>
                }

                {this.state.createPracticeModal &&
                <Modal open={this.state.createPracticeModal}
                       closeIcon
                       size='tiny'
                       style={{height: 400}}
                       onClose={this.onCreateModalClose}
                >
                    <Modal.Header>Create Practice</Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <PracticeCreate data={this.state.createPractice}
                                            onCancel={this.onCreateModalClose}
                                            onPracticeSaved={this.onPracticeAdded}/>
                        </Modal.Description>
                    </Modal.Content>
                </Modal>
                }
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
           
        );
            }}
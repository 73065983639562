import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import React, {useState} from 'react';

export default function Contacts(props) {
  // `value` will be the parsed phone number in E.164 format.
  // Example: "+12133734253".
  const [mobileValue, setMobileValue] = useState();
  // If `country` property is not passed
  // then "International" format is used.
  // Otherwise, "National" format is used.

  return (
      <PhoneInput
          placeholder="Enter phone number"
          defaultCountry="AU"
          value={mobileValue}
          onChange={setMobileValue}/>

  );

}
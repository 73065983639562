import React, {Component} from 'react';
import {
    Button,
    Dimmer,
    Form,
    Loader,
    Message,
    Segment,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {Auth} from '../../helpers/Auth.js';
import {Redirect} from 'react-router-dom';
import {User} from '../../helpers/User';
import '../../index.css';
import medlogo from '../../assets/img/logo-white.png';

export default class LoginBox extends Component {

    constructor(props) {
        super(props);
        this.state = {email: '',
            password: '',
            loginFailed: null,
            authenticated: false,
            authenticating: false,
            errorMessage: null
        }
    }

    handleFormChange = (e, {name, value}) => {
        this.setState({[name]: value})
    };

    /**
     * Logs the user in, on login success retrieves the user's primary company
     * and sets the state to redirect to the dashboard
     * @param event
     */
    handleOnLogin = (event) => {
        this.setState({authenticating: true});
        const that = this;
        new Auth().login(this.state.email, this.state.password, (token, userId) => {
            const user = new User(userId);
            user.fetchUserData();
            user.fetchCurrentCompany().then(response => {
                if (response.ok && response.data.data.length > 0) {
                    user.setCompany(response.data.data[0].id);
                }
            }).finally(() => {
                that.setState({
                    loginFailed: false,
                    authenticated: true,
                    authenticating: false,
                });
            });
        }, (errorMessage) => {
            that.setState({loginFailed: true, authenticating: false, errorMessage: errorMessage});
        });
    };

    render() {
        const {email, password, loginFailed, authenticated, authenticating} = this.state;

        if (authenticated) {
            return <Redirect to="/practice-selector"/>;
        }

        let message = "";
        if (loginFailed) {
            message = "Login failed, please check your username and password.";
        } else if (this.props.status) {
            switch (this.props.status) {
                case "expired":
                    message = "For security reasons your session has expired, please login again";
                    break;
            }
        }

        return (
           
                <div class="flex min-h-screen items-center bg-white w-full">
                <div class="w-1/2 items-center h-screen bg-sky-800 md:block hidden">
                    <img class="bg-cover items-center md:block hidden" src={medlogo}></img>
                </div>
                    <div class="md:w-1/2 max-w-lg mx-auto my-24 px-4 py-5 shadow-none">
                    
                        <Form size="large">

                            <Dimmer.Dimmable blurring as={Segment} stacked>
                                <Dimmer active={authenticating}>
                                    <Loader indeterminate
                                            active>Authenticating...</Loader>
                                </Dimmer>
                                <Form.Input fluid icon="user" iconPosition="left"
                                            value={email} name="email"
                                            placeholder="Email"
                                            onChange={this.handleFormChange}/>
                                <Form.Input fluid icon="lock" iconPosition="left"
                                            value={password} name="password"
                                            placeholder='Password' type='password' onChange={this.handleFormChange}/>
                                <Button name="login-button" color='blue' fluid size='large' onClick={this.handleOnLogin}>
                                    Login
                                </Button>

                            </Dimmer.Dimmable>
                        </Form>
                        {message.length > 0 && <Message color="red">
                        {message}
                        </Message>}
                        <a class="" href="/login" data-test="Link"><span
                            class="block  p-5 text-center text-gray-800  text-xs ">Already have an account?</span></a>
                    </div>
                </div>
            
            )
    }
}

LoginBox.propTypes = {
    status: PropTypes.string
};
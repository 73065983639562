import {ApiError, AuthenticatedApiEndPoint} from '../../helpers/ApiEndPoint';
import {Auth} from '../../helpers/Auth';

const apiRequest = (
    url, practiceId, filters = {}, onSuccess, onFinal, onError) => {
  if (practiceId) {
    filters = {...filters, ...{'filter[practice_id]': practiceId}};
  }

  const endPoint = new AuthenticatedApiEndPoint(new Auth().getJWT(), url);
  endPoint.get(filters).then(response => {
    if (response.ok) {
      onSuccess(response.data.data, response.data.meta);
    }
    else {
      if (onError) {
        onError(response);
      }
      else {
        ApiError(response, 'Could not retrieve the selected data');
      }
    }
  }).finally(() => {
    if (onFinal) {
      onFinal();
    }
  });
};

export {apiRequest};
import React, {Component} from 'react';
import {Label, Message} from 'semantic-ui-react';
import {Form} from 'formsy-semantic-ui-react';
import {Auth} from '../../helpers/Auth';
import {ClientAddressesEP, ClientEP} from '../../config/ApiEndpoints';
import PropTypes from 'prop-types';
import {ApiError, AuthenticatedApiEndPoint} from '../../helpers/ApiEndPoint';
import {DisplayNotification} from '../../helpers/Notifications';
import {Redirect} from 'react-router-dom';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import AddressFinder from '../helpers/addresslookup/AddressForm';
import PhoneInput from '../helpers/contacts/contacts';
import {utcToZonedTime} from 'date-fns-tz';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import {Box, Button, CircularProgress, Grid, Table} from '@mui/material/';

export class ClientEdit extends Component {
    constructor(props) {
        super(props);
        const date = (this.props.data.dob);
        const intialDob = utcToZonedTime(date, {timeZone: 'UTC'});
        this.state = {
            Dob: intialDob,
            firstname: undefined,
            lastname: undefined,
            isLoading: false,
            formErrors: {firstname: undefined, lastname: undefined},
            openTab: null,
            setOpenTab: null,
            mobileValue: undefined,
            resi: {
                fullAddress: '',
                type: '',
                street_address_1: '',
                street_address_2: '',
                suburb: '',
                state: '',
                postcode: '',
                country_iso3: '',
            },
            post: {
                fullAddress: "",
                type:"",
                street_address_1: "",
                street_address_2: "",
                suburb: "",
                state: "",
                postcode: "",
                country_iso3: ""
                },
                addresses: [],
                isClientAddressLoading: false,
                physicalAddresses: {
                    fullAddress: "",
                    type:"",
                    street_address_1: "",
                    street_address_2: "",
                    suburb: '',
                    state: '',
                    postcode: '',
                    country_iso3: '',
                },
            postalAddresses: {
                fullAddress: '',
                type: '',
                street_address_1: '',
                street_address_2: '',
                suburb: '',
                state: '',
                postcode: '',
                country_iso3: '',
            },
        };

    }

    componentDidMount() {
        this.loadClientAddresses();
    }

    handleDobChange = (e, data) => {
        if (data.value) {
            const newDateValue = new Date(data.value);
            return this.setState({ Dob: newDateValue });
        } else {
            return data.value;
        }
    };

    handlePhysicalAddressChange = (e) => {
        console.log(e);
    
        this.setState(prevState => ({
            resi: (e.physical)}));
    };

    handlePostalAddressChange = (e) => {
        console.log(e);
        this.setState(prevState => ({
            post: (e.postal)}));

    };

    validate = () => {
        if (this.state.firstname.trim().length === 0) {
            this.setState({formErrors: {firstname: 'Client Name is required'}});
            return false;
        }
        return true;
    };

    // Get Client Addresses
    loadClientAddresses = () => {
        const endPoint = new AuthenticatedApiEndPoint(new Auth().getJWT(), ClientAddressesEP, {clientid: this.props.data.id});
        endPoint.get().then(response => {
            if (response.ok) {
                this.setState({addresses: response.data.data});
                this.filterPhysicalAddress();
                this.filterPostalAddress();
            } 
            else {
                ApiError(response, "Could not retrieve the addresses for this client");
            }
        }).finally(() => {
            this.setState({isClientAddressLoading: false});
        })
    };

   // Filter Postal and Physical addresses to get last entry
    filterPhysicalAddress = () => {
        this.setState({
            physicalAddresses: this.props.data.addresses?.filter( (auto) => auto.type.includes("physical"))
            })
        const maxPhysicalId = Math.max.apply(Math, this.state.physicalAddresses.map(function(o) { return o.id; }));
        this.setState({physicalAddresses: this.state.physicalAddresses.filter(function(o) {return o.id === maxPhysicalId})});
    }

    filterPostalAddress = () => {
        this.setState({
            postalAddresses: this.props.data.addresses?.filter( (auto) => auto.type.includes("postal"))
            })
        const maxPostalId = Math.max.apply(Math, this.state.postalAddresses.map(function(o) { return o.id; }));
        this.setState({postalAddresses: this.state.postalAddresses.filter(function(o) {return o.id === maxPostalId})})
    }

    // Post new details
    onValidSubmit = (formData) => {

        const onResponse = (response) => {
            if (response.ok) {
                this.setState({error: false, saved: true});
                if (this.props.onClientSaved) {
                    this.props.onClientSaved();
                }
                DisplayNotification("success", null, "Client was saved successfully")
            } else {
                this.setState({saved: false, error: true, errors: response.data.errors});
            }
        };

        if (this.props.data.users_id) {
            this.update(formData, onResponse);
            if (this.state.resi.fullAddress !== "") { 
            this.physicalAddress();
            }
            if (this.state.post.fullAddress !== "") { 
            this.postalAddress();
            }
            }
        else {
            this.save(formData, onResponse)
        }
    };

    save = (formData, onResponse) => {
        this.setState({isLoading: true});
        const endPoint2 = new AuthenticatedApiEndPoint(new Auth().getJWT(), ClientEP, {clientid: this.props.data.id});
        endPoint2.put(formData)
            .then(onResponse).finally(() => {
            this.setState({isLoading: false})
            .then(("/clientP"));
        })
    };

    update = (formData, onResponse) => {
        this.setState({isLoading: true});
        const endPoint2 = new AuthenticatedApiEndPoint(new Auth().getJWT(), ClientEP, {clientid: this.props.data.id});
        endPoint2.put(formData)
            .then(onResponse).finally(() => {
            this.setState({isLoading: false});
            (<Redirect to="/clientP"/>);
        })
    };

    physicalAddress = () => {
        this.setState({isLoading: true});
        const endPoint2 = new AuthenticatedApiEndPoint(new Auth().getJWT(), ClientAddressesEP, {clientid: this.props.data.id});
        endPoint2.post(this.state.resi,
            this.state.resi.type="physical",
            this.state.resi.country_iso3="AUS")
            .finally(() => {
            this.setState({isLoading: false});
            (<Redirect to="/clientP"/>);
        })
    };

    postalAddress = () => {
        this.setState({isLoading: true});
        const endPoint2 = new AuthenticatedApiEndPoint(new Auth().getJWT(), ClientAddressesEP, {clientid: this.props.data.id});
        endPoint2.post(
            this.state.post,
            this.state.post.type="postal",
            this.state.post.country_iso3="AUS")
            .finally(() => {
            this.setState({isLoading: false});
            (<Redirect to="/clientP"/>);
        })
    };

    render() {
        let errors = "";
        if (this.state.errors) {
            errors += Object.keys(this.state.errors).map((error, index) => (
                this.state.errors[error][0]
            ));
        }
        
        return (

            <Grid
                class=" items-center justify-center px-4 max-w max-h overflow-x-auto ">
                <Tabs>
                    <TabList>
                        <Tab>Personal</Tab>
                        <Tab>Medical</Tab>
                        <Tab>Add Family</Tab>
                    </TabList>


                    <Form
                        ref={ref => this.form = ref}
                        onValidSubmit={this.onValidSubmit} onSubmit={this.onSubmit} success={this.state.saved === true} error={this.state.error === true}
                        loading={this.state.isLoading === true}
                    >
                        <TabPanel>

                                <div class="md:grid md:grid-cols-2 w-full">
                                    <Form.Input required
                                                fluid
                                                defaultValue={this.props.data.firstname}
                                                width={15}
                                                name="firstname"
                                                label="First Name"
                                                placeholder="First Name"
                                                validationErrors={{
                                                    isDefaultRequiredValue: 'Client Name is Required',
                                                }}
                                                errorLabel={<Label color="red" pointing/>}

                                    />
                                    <Form.Input name="lastname"
                                                width={15}
                                                fluid
                                                defaultValue={this.props.data.lastname}
                                                label="Last Name"
                                                errorLabel={<Label color="red" pointing/>}
                                                placeholder="Last Name"/>
                                </div>

                                <div class="md:grid md:grid-cols-2 w-full">
                                    <Form.Input required
                                                fluid
                                                width={15}
                                                defaultValue={this.props.data.user.email}
                                                name="email"
                                                label="Email"
                                                placeholder="Email"
                                                validationErrors={{
                                                    isDefaultRequiredValue: 'Client Email is Required',
                                                }}
                                                errorLabel={<Label color="red" pointing/>}

                                    />

                                    <SemanticDatepicker
                                        label="DOB"
                                        name="dob"
                                        format="DD/MM/YYYY"
                                        required={this.props.required}
                                        onChange={this.handleDobChange.bind(this)}
                                        showToday={true}
                                        value={this.state.Dob}
                                        validationErrors={{
                                            isDefaultRequiredValue: 'Client DOB is Required',
                                        }}
                                        errorLabel={<Label color="red" pointing/>}
                                    />
                                    <Form.Input type="hidden"
                                                fluid
                                                width={15}
                                                defaultValue={this.state.Dob}
                                                value={this.state.Dob}
                                                name="dob"

                                    />
                                </div>

                            <div class="md:grid md:grid-cols-1 w-full">
                                <AddressFinder class="input"
                                               onPhysicalChange={this.handlePhysicalAddressChange}
                                               resi={this.state.resi}
                                               physicalAddresses={this.state.physicalAddresses}
                                               onPostalChange={this.handlePostalAddressChange}
                                               post={this.state.post}
                                               postalAddresses={this.state.postalAddresses}
                                               onSubmit={this.onValidSubmit}
                                               ClientAddressData={this.props.data.id}
                                />
                                        
                                </div>
                                <div class="md:grid md:grid-cols-2 w-full">
                                    <PhoneInput/>
                                    <Form.Input
                                        fluid
                                        width={15}
                                        defaultValue={this.props.data.user.mobile}
                                        name="mobile"
                                        label="Mobile"
                                        placeholder="Mobile"

                                    />
                                    <Form.Input
                                                fluid
                                                width={15}
                                                defaultValue={this.props.data.homephone}
                                                name="homephone"
                                                label="Home Phone"
                                                placeholder="Home Phone"

                                    />
                                </div>
                                <div class="md:grid md:grid-cols-2 w-full">
                                    <Form.Input
                                                fluid
                                                width={15}
                                                defaultValue={this.props.data.workphone}
                                                name="workphone"
                                                label="Work Phone"
                                                placeholder="Work Phone"

                                    />
                                    <Form.Input
                                                fluid
                                                width={15}
                                                defaultValue={this.props.data.fax}
                                                name="fax"
                                                label="Fax"
                                                placeholder="Fax"

                                    />
                                </div>
                                <Form.Group widths={1}>
                                    <Form.Button primary>Save</Form.Button>
                                    <Form.Button type="button" onClick={this.props.onCancel}>Cancel</Form.Button>
                                </Form.Group>
                                <Message success header="Save Successful"/>
                                <Message error header="There's been a problem" content={errors}/>
                        </TabPanel>
                        <TabPanel>
                                <div class=" w-full inline-flex p-2">
                                    <div class="w-7/12">
                                        <Form.Input 
                                                    fluid
                                                    defaultValue={this.props.data.pracfirstname}
                                                    //width={10}
                                                    name="practitioner"
                                                    label="Primary Practitioner"
                                                    placeholder="Dr Joe Blogs"
                                            />
                                    </div>
                                    <div class="w-full">
                                        
                                    </div>
                                </div>

                                <div class=" w-full inline-flex py-2">
                                    <div class="w-11/12 px-2">
                                        <Form.Input 
                                                    fluid
                                                    defaultValue={this.props.data.medicarenum}
                                                    //width={10}
                                                    name="medicarenum"
                                                    label="Medicare Card"
                                                    placeholder="Medicare Card"
                                        />
                                    </div>
                                    <div class="w-2/12">
                                        <Form.Input 
                                                    fluid
                                                    defaultValue={this.props.data.medicareref}
                                                    //width={1}
                                                    name="medicareref"
                                                    label="REF"
                                                    placeholder="Ref"
                                        />
                                    </div>
                                    <div class="w-5/12 px-2">
                                        <Form.Input 
                                                    fluid
                                                    defaultValue={this.props.data.medicareexp}
                                                    //width={8}
                                                    name="medicareexp"
                                                    type="date"
                                                    label="Expiry"
                                                    placeholder="mm/yyyy"
                                        />
                                    </div>
                                    <div class="w-full"></div>
                                </div>

                                <div class=" w-full inline-flex py-2">
                                    <div class="w-11/12 px-2">
                                        <Form.Input 
                                                    fluid
                                                    defaultValue={this.props.data.dvanumber}
                                                    //width={10}
                                                    name="dvanum"
                                                    label="DVA Number"
                                                    placeholder="DVA Number"
                                        />
                                    </div>
                                    <div class="w-5/12">
                                        <Form.Input 
                                                    fluid
                                                    defaultValue={this.props.data.dvaexp}
                                                    //width={8}
                                                    name="medicareexp"
                                                    type="date"
                                                    label="Expiry"
                                                    placeholder="mm/yyyy"
                                        />
                                    </div>
                                    <div class="w-full"></div>
                                </div>
                                <div class=" w-full inline-flex py-2">
                                    <div class="w-11/12 px-2">
                                        <Form.Input 
                                                    fluid
                                                    defaultValue={this.props.data.fundnumber}
                                                    //width={10}
                                                    name="fundnum"
                                                    label="Health Fund Number"
                                                    placeholder="Fund Number"
                                        />
                                    </div>
                                    <div class="w-11/12">
                                        <Form.Input 
                                                    fluid
                                                    defaultValue={this.props.data.healthfund}
                                                    //width={8}
                                                    name="healthfund"
                                                    label="Health Fund"
                                                    placeholder="Fund"
                                        />
                                    </div>
                                    <div class="w-full"></div>
                                </div>
                                <div class=" w-full inline-flex py-2">
                                    <div class="w-11/12 px-2">
                                        <Form.Input 
                                                    fluid
                                                    defaultValue={this.props.data.ndisnumber}
                                                    //width={10}
                                                    name="ndisnum"
                                                    label="NDIS Number"
                                                    placeholder="NDIS Number"
                                        />
                                    </div>
                                    <div class="w-11/12">
                                        <Form.Input 
                                                    fluid
                                                    defaultValue={this.props.data.healthfund}
                                                    //width={8}
                                                    name="ndisfund"
                                                    label="NDIS Funding"
                                                    placeholder="NDSI Funding"
                                        />
                                    </div>
                                    <div class="w-full"></div>
                                </div>
                                <Form.Group widths={1}>
                                    <Form.Button primary>Save</Form.Button>
                                    <Form.Button type="button" onClick={this.props.onCancel}>Cancel</Form.Button>
                                </Form.Group>
                                <Message success header="Save Successful"/>
                                <Message error header="There's been a problem" content={errors}/>
                        </TabPanel>

                        <TabPanel>
                                <div class="md:grid md:grid-cols-2 w-full">
                                <Form.Input 
                                                fluid
                                                defaultValue={this.props.data.family_relationship}
                                                width={15}
                                                name="family_relationship"
                                                label="Relationship"
                                                placeholder="Relationship"
                                    />
                                    <div></div>
                                    <Form.Input 
                                                fluid
                                                defaultValue={this.props.data.family_firstname}
                                                width={15}
                                                name="family_firstname"
                                                label="First Name"
                                                placeholder="First Name"
                                    />
                                    <Form.Input name="family_lastname"
                                                width={15}
                                                fluid
                                                defaultValue={this.props.data.family_lastname}
                                                label="Last Name"
                                                placeholder="Last Name"/>
                                </div>
                                <div class="md:grid md:grid-cols-2 w-full">
                                    <Form.Input 
                                                fluid
                                                width={15}
                                                defaultValue={this.props.data.family_email}
                                                name="email"
                                                label="Email"
                                                placeholder="Email"

                                    />

                                    <Form.Input 
                                                fluid
                                                width={15}
                                                defaultValue={this.props.data.family_dob}
                                                name="dob"
                                                type='date'
                                                label="DOB"
                                                placeholder="DOB"

                                    />
                                </div>
                                <div class="md:grid md:grid-cols-2 w-full">
                                    <Form.Input
                                                fluid
                                                width={15}
                                                defaultValue={this.props.data.family_mobile}
                                                name="mobile"
                                                label="Mobile"
                                                placeholder="Mobile"

                                    />
                                    <Form.Input
                                                fluid
                                                width={15}
                                                defaultValue={this.props.data.family_homephone}
                                                name="homephone"
                                                label="Home Phone"
                                                placeholder="Home Phone"

                                    />
                                </div>
                            <Form.Group widths={1}>
                                <Form.Button primary>Save</Form.Button>
                                <Form.Button type="button"
                                             onClick={this.props.onCancel}>Cancel</Form.Button>
                            </Form.Group>
                            <Message success header="Save Successful"/>
                            <Message error header="There's been a problem"
                                     content={errors}/>

                        </TabPanel>
                    </Form>
                </Tabs>
            </Grid>
           ); 
    }

}

ClientEdit.propTypes = {
    onClientSaved: PropTypes.func,
    onCancel: PropTypes.func.isRequired,
    clientData: PropTypes.object
};
import React, {Component} from 'react';
import {Dashboard} from './layout/Dashboard';
import Navbar from './menus/Navbar';
import SideNavBarWide from './menus/SideNavBarWide3';
import {PrivateRoute} from '../helpers/PrivateRoute';
import {Switch} from 'react-router-dom';

import {SemanticToastContainer} from 'react-semantic-toasts';
import 'react-semantic-toasts/styles/react-semantic-alert.css';
import {PractitionerList} from './practitioners/PractitionerList';
import {PracticeList} from './practices/PracticeList';
import {ClientList} from './clients/ClientList';
import {ClientProfile} from './clients/ClientProfile';
import {UserList} from './users/UserList';
import {LocationList} from './practices/locations/LocationList';
import {ServiceList} from './services/Services';
import {AppointmentsList} from './appointments/AppointmentsList';
import {Appointments} from './appointments/Appointments';
import {AppointmentsTable} from './appointments/AppointmentsTable';
import {Container, Grid} from '@material-ui/core';

export class MainSite extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <Container>
          <Grid container>
            <Grid item xs={12} lg={12}>
              <SideNavBarWide></SideNavBarWide>
            </Grid>
            <Grid item xs={12} lg={12}>

              <Navbar></Navbar>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Switch>
                <PrivateRoute path="/dashboard" component={Dashboard}/>


                <PrivateRoute strict exact path="/calendar"
                              component={AppointmentsList}/>

                <PrivateRoute strict exact path="/calendar"
                              component={Appointments}/>

                <PrivateRoute strict exact path="/appointments"
                              component={AppointmentsTable}/>

                <PrivateRoute strict exact path="/clients"
                              component={ClientList}/>
                <PrivateRoute strict exact path="/clientP"
                              component={ClientProfile}/>
                <PrivateRoute strict exact path="/practitioners"
                              component={PractitionerList}/>


                <PrivateRoute strict exact path="/practices"
                                component={PracticeList}/>

                <PrivateRoute strict exact path="/locations"
                              component={LocationList}/>
                <PrivateRoute strict exactpath="/services"
                              component={ServiceList}/>

                <PrivateRoute strict exact path="/users"
                              component={UserList}/>
                <PrivateRoute strict exact path="/" component={Dashboard}/>

                <SemanticToastContainer position="bottom-left"/>
              </Switch>
            </Grid>
          </Grid>
          </Container>

    )
    };
}
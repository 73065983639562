import React, {Component} from 'react';
import {AuthenticatedApiEndPoint} from '../../helpers/ApiEndPoint';
import {UsersEP} from '../../config/ApiEndpoints';
import {Auth} from '../../helpers/Auth.js';
import {Modal, Pagination} from 'semantic-ui-react';
import {UserEdit} from './UserEdit';
import {UserDeleter} from './UserDeleter';
import {UserCopy} from './UserCopy';
import {UserCreate} from './UserCreate';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import '../../styles/output.css';
import 'tippy.js/dist/tippy.css';
import SearchBar from '../../components/helpers/searchbox/SearchBar';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import {
    copyRecord,
    deleteRecord,
    editDetails,
    newUser,
    showProfile,
    userStatus,
    userVerified,
} from '../helpers/popovers/PopOvers';

export class UserList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: [],
            isLoading: false,
            editUserModal: false,
            deleteUser: undefined,
            syncingUser: false,
            syncroniseUser: null,
            filter: null,
            activePage: 1, 
            totalPages: 1, 
            lastname: undefined,
            sort: null,
        };
        this.filterDebouncer = AwesomeDebouncePromise(this.loadUsers, 200);
    }

    componentWillMount() {
        this.loadUsers();
    }

    loadUsers = () => {
        this.setState({isLoading: true});
        const endPoint = new AuthenticatedApiEndPoint(new Auth().getJWT(), UsersEP);
        endPoint.get({
            ... (this.state.filter ? {} : {page: this.state.activePage}),
            sort: 'lastname',
            'filter[lastname]=': this.state.filter
            })
            .then(response => {
                if (response.ok) {
                    this.setState({userData: response.data.data, totalPages: response.data.meta.last_page});
                }
            })
            .finally(() => {
            this.setState({isLoading: false});
        })
    };
    handlePaginationChange = (e, {activePage}) => {
        this.setState({activePage}, this.loadUsers)
    };

    handleFilterChange = async (e) => {
        const {value} = e.target
        this.setState({filter: value}, () => {
            this.filterDebouncer()
        });
    }

    onUserAdded = () => {
        this.onModalClose();
        this.loadUsers();

    };

    onUserDeleted = (data) => {
        this.loadUsers();
        this.setState({deleteUser: null});
    };

    onUserSyncronised = () => {
        this.setState({syncroniseUser: null});
    };

    handleDeleteUser = (data) => {
        this.setState({deleteUser: data.id});
    };

    handleSyncroniseUser = (data) => {
        this.setState({syncroniseUser: data.id});
    };

    handleEditUser = (data) => {
        this.setState({editUser: data, editUserModal: true});
    };

    onModalClick = () => this.setState({editUserModal: true, editUser: false});
    onModalClose = () => this.setState({editUserModal: false, editUser: false});

    handleCreateUser = () => {
        this.setState({createUserModal: true});
    };

    onCreateModalClick = () => this.setState({createUserModal: true, createUser: false});
    onCreateModalClose = () => this.setState({createUserModal: false, createUser: false});

    handleCopyUser = (data) => {
        this.setState({copyUser: data, copyUserModal: true});
    };

    onCopyModalClick = () => this.setState({copyUserModal: true, copyUser: false});
    onCopyModalClose = () => this.setState({copyUserModal: false, copyUser: false});

    render() {

        const userData = this.state.userData;
        const auth = new Auth();
        return (
            <Container fluid
                       className="p-2 min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
                <Row>
                    <Col lg={8} xs={4} md={8}>
                        <h1 className="text-2xl p-2">Users</h1>
                    </Col>

                    <Col lg={4} xs={4} md={4}>
                        <OverlayTrigger
                            placement="top"
                            delay={{show: 250, hide: 400}}
                            overlay={newUser}
                        >
                            <div class="float-right">
                                <Button onClick={() => this.handleCreateUser()}
                                        variant="secondary">
                                    New User
                                </Button>
                            </div>
                        </OverlayTrigger>
                    </Col>

                </Row>
                <Row>
                    {this.state.isLoading === true &&
                    <Col lg="auto" xs={2} md={4}>

                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>

                    </Col>
                    }
                    <Col lg={8} xs={12} md={12}>
                        {this.state.totalPages > 1 &&
                        <Pagination
                            defaultActivePage={1}
                            totalPages={this.state.totalPages}
                            onPageChange={this.handlePaginationChange}
                            pointing
                            secondary
                        />
                        }
                    </Col>
                </Row>

                <Row>
                    <Col lg={12} xs="auto" md="auto">
                        <Table responsive
                               className="table-auto w-full overflow-auto  m-1 divide-y divide-purple-400">
                            <thead className="bg-gray-50">
                            <tr class="">
                                <th
                                    scope="col"
                                    className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >User
                                </th>
                                <th
                                    scope="col"
                                    className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >User Type
                                </th>
                                    <th
                                        scope="col"
                                        className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >Status</th>
                                    <th colspan="3"
                                        scope="col"
                                        className="px-2 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >Actions</th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                <tr>
                                    <td>
                                        <SearchBar 
                                        handleFilterChange={this.handleFilterChange}
                                        filter={this.state.filter}
                                        />
                                    </td>
                                </tr>
                                {userData.map((data) => {
                                    return <tr key={data.id} >
                                        <td className="px-2 py-4 whitespace-nowrap">
                                            <Container className="items-center">
                                                <Row>
                                                    <Col>
                                                        <Row>
                                                            <Col
                                                                className="p-1 text-sm font-medium text-gray-900 fas fa-user">
                                                                &nbsp;{data.firstname} {data.lastname}
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col
                                                                className="p-1 text-sm text-gray-500 inline-flex fas fa-at">
                                                                &nbsp;{data.email}
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col
                                                                className="p-1 text-sm text-gray-500 inline-flex fas fa-phone">
                                                                <span>&nbsp;{data.mobile}</span>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Container>
                                    </td>
                                    <td className="px-2 py-4 whitespace-nowrap">
                                        <div className="text-sm font-medium text-gray-900">
                                            {data.usertype}
                                        </div>
                                    </td>
                                    <td className="px-2 py-4 whitespace-nowrap">
                                        <Container
                                            className="text-sm font-medium text-gray-900">
                                            {data.verified > false &&
                                            <OverlayTrigger
                                                placement="top"
                                                delay={{show: 250, hide: 400}}
                                                overlay={userVerified}
                                            >
                                                    <span
                                                        className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-200 text-green-800">
                                                        Verified
                                                    </span>
                                            </OverlayTrigger>}

                                            {data.verified < true &&
                                            <OverlayTrigger
                                                placement="top"
                                                delay={{show: 250, hide: 400}}
                                                overlay={userVerified}
                                            >
                                                <Badge bg="warning" text="dark">
                                                    Not Verified
                                                </Badge>
                                            </OverlayTrigger>}
                                            <br></br>
                                            {data.approved > false &&
                                            <OverlayTrigger
                                                placement="top"
                                                delay={{show: 250, hide: 400}}
                                                overlay={userStatus}
                                            >
                                                <Badge bg="Success" text="dark">
                                                    Approved
                                                </Badge>
                                            </OverlayTrigger>}

                                            {data.approved < true &&
                                            <OverlayTrigger
                                                placement="top"
                                                delay={{show: 250, hide: 400}}
                                                overlay={userStatus}
                                            >
                                                <Badge bg="danger">
                                                    Not Approved
                                                </Badge>
                                            </OverlayTrigger>}
                                        </Container>
                                    </td>
                                        <td className="px-1 py-4 whitespace-nowrap">
                                            <Row>
                                                <Col className="p-1">
                                                    <OverlayTrigger
                                                        placement="top"
                                                        delay={{
                                                            show: 250,
                                                            hide: 400,
                                                        }}
                                                        overlay={editDetails}
                                                    >
                                                        <a onClick={() => this.handleEditUser(
                                                            data)}
                                                           className="text-sm font-medium text-decoration-none text-bluegray-700 cursor-pointer d-flex fas fa-calendar-alt">
                                                            <span>&nbsp;Edit Profile</span>
                                                        </a>
                                                    </OverlayTrigger>
                                                </Col>
                                                <Col className="p-1">
                                                    <OverlayTrigger
                                                        placement="top"
                                                        delay={{
                                                            show: 250,
                                                            hide: 400,
                                                        }}
                                                        overlay={deleteRecord}
                                                    >
                                                        <a onClick={() => this.handleDeleteUser(
                                                            data)}
                                                           className="text-sm font-medium text-red-800 cursor-pointer text-decoration-none  d-flex fas fa-trash">
                                                            <span>&nbsp;Delete User</span>
                                                        </a>
                                                    </OverlayTrigger>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="p-1">
                                                    <OverlayTrigger
                                                        placement="top"
                                                        delay={{
                                                            show: 250,
                                                            hide: 400,
                                                        }}
                                                        overlay={showProfile}
                                                    >
                                                        <a onClick={() => this.handleShowUser(
                                                            data)}
                                                           className="text-sm font-medium text-green-900 cursor-pointer text-decoration-none  d-flex fas fa-user">
                                                            <span>&nbsp;Show Profile</span>
                                                        </a>
                                                    </OverlayTrigger>
                                                </Col>
                                                <Col className="p-1">
                                                    <OverlayTrigger
                                                        placement="top"
                                                        delay={{
                                                            show: 250,
                                                            hide: 400,
                                                        }}
                                                        overlay={copyRecord}
                                                    >
                                                        <a onClick={() => this.handleCopyUser(
                                                            data)}
                                                           className="text-sm font-medium text-gray-900 cursor-pointer text-decoration-none d-flex fas fa-calendar-alt">
                                                            <span>&nbsp;Copy User</span>
                                                        </a>
                                                    </OverlayTrigger>
                                                </Col>
                                            </Row>
                                        </td>
                                    </tr>
                                })
                                }
                                </tbody>
                        </Table>

                        {this.state.deleteUser &&
                        <UserDeleter userId={this.state.deleteUser}
                                     open={Number.isInteger(
                                         this.state.deleteUser)}
                                     onCancel={() => this.setState(
                                         {deleteUser: null})}
                                     onDeleteUser={this.onUserDeleted}/>
                        }

                        {this.state.editUserModal &&
                        <Modal open={this.state.editUserModal}
                               closeIcon
                               size="large"
                            //style={{height: 400}}
                       dimmer='blurring'
                       //style={{ marginBottom: 10, height: 400 }}
                       onClose={this.onModalClose}
                >
                    <Modal.Header >Edit User</Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <UserEdit data={this.state.editUser} onCancel={this.onModalClose}
                                         onUserSaved={this.onUserAdded}/>
                        </Modal.Description>
                    </Modal.Content>
                </Modal>
                }

                {this.state.copyUserModal &&
                <Modal open={this.state.copyUserModal}
                       closeIcon
                       size='tiny'
                       style={{height: 400}}
                       onClose={this.onCopyModalClose}
                >
                    <Modal.Header>Copy User</Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <UserCopy data={this.state.copyUser} onCancel={this.onCopyModalClose}
                                         onUserSaved={this.onUserAdded}/>
                        </Modal.Description>
                    </Modal.Content>
                </Modal>
                }

                {this.state.createUserModal &&
                <Modal open={this.state.createUserModal}
                       closeIcon
                       size='tiny'
                       style={{height: 400}}
                       onClose={this.onCreateModalClose}
                >
                    <Modal.Header>Create User</Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <UserCreate data={this.state.createUser}
                                        onCancel={this.onCreateModalClose}
                                        onUserSaved={this.onUserAdded}/>
                        </Modal.Description>
                    </Modal.Content>
                </Modal>
                }

                    </Col>
                </Row>
            </Container>
        );
    }
}
import React, {Component} from 'react';
import {ClientAddressesEP, ClientEP} from '../../config/ApiEndpoints';
import {Auth} from '../../helpers/Auth.js';
import {PropTypes} from 'prop-types';
import {differenceInCalendarYears} from 'date-fns';
import {utcToZonedTime} from 'date-fns-tz';
import '../../styles/output.css';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import {ApiError, AuthenticatedApiEndPoint} from '../../helpers/ApiEndPoint';
import Editor from '../helpers/CkEditor/Editor';
import ClinicalNotes from '../helpers/NewsFeeds/ClinicalNotes';
import {Form} from 'formsy-semantic-ui-react';
import {Modal} from 'semantic-ui-react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

export class ClientProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            clientData: [],
            isLoading: false,
            editClientModal: false,
            deleteClientModel: false,
            deleteClient: undefined,
            showClient: undefined,
            syncingClient: false,
            syncroniseClient: null,
            clientid: null,
            addresses: [],
            physicalAddresses: [],
            postalAddresses: [],
        };
    }

    componentWillMount() {
        this.loadClient();
        this.loadClientAddresses();
    }
    

    
    loadClient = () => {
        this.setState({isLoading: true});
        const api = new AuthenticatedApiEndPoint(new Auth().getJWT(),ClientEP, {clientid: this.props.location.state.clientId})
        //const api = ApiClientFactory.createAuthenticatedApi(new Auth().getJWT());
        api.get().then(response => {
            if (response.ok) {
                this.setState({clientData: response.data.data});
                this.setState({addressData: response.data.addresses});
            }
        }).finally(() => {
            this.setState({isLoading: false});
        })
    };

    // Get Client Addresses
    loadClientAddresses = () => {
        const endPoint = new AuthenticatedApiEndPoint(new Auth().getJWT(),
            ClientAddressesEP, {clientid: this.props.location.state.clientId});
        endPoint.get().then(response => {
            if (response.ok) {
                this.setState({addresses: response.data.data});
                this.filterPhysicalAddress();
                this.filterPostalAddress();
            }
            else {
                ApiError(response,
                    "Could not retrieve the addresses for this client");
            }
        }).finally(() => {
            this.setState({isClientAddressLoading: false});
        })
    };

    // Filter Postal and Physical addresses to get last entry
    filterPhysicalAddress = () => {
        this.setState({
            physicalAddresses: this.state.addresses?.filter(
                (auto) => auto.type.includes("physical"))
        })
        const maxPhysicalId = Math.max.apply(Math,
            this.state.physicalAddresses.map(function(o) { return o.id; }));
        this.setState({
            physicalAddresses: this.state.physicalAddresses.filter(
                function(o) {return o.id === maxPhysicalId})
        });
    }

    filterPostalAddress = () => {
        this.setState({
            postalAddresses: this.state.addresses?.filter(
                (auto) => auto.type.includes("postal"))
        })
        const maxPostalId = Math.max.apply(Math,
            this.state.postalAddresses.map(function(o) { return o.id; }));
        this.setState({
            postalAddresses: this.state.postalAddresses.filter(
                function(o) {return o.id === maxPostalId})
        })
    }

    handleMedical = (data) => {
        this.setState({copyClient: data, MedicalModal: true});
    };

    onMedicalModalClick = () => this.setState(
        {MedicalModal: true, copyClient: false});
    onMedicalModalClose = () => this.setState(
        {MedicalModal: false, copyClient: false});

    render() {
        const physicalAddresses = this.state.physicalAddresses[0];
        const postalAddresses = this.state.postalAddresses[0];
        const clientData = this.state.clientData;
        const auth = new Auth();
        const date = (clientData.dob)
        const date2 = utcToZonedTime(date, {timeZone: 'UTC'})
        const now = new Date();
        console.log(date2) //Date format
        //const Dob = parseISO(date, "dd/MM/yyyy", new Date())
        const age = differenceInCalendarYears(new Date(), date2)
        //const Dob = format(new Date(date), 'dd MMM yyyy')
        return (
            <Container fluid
                       className="p-2 min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
                <Row>
                    <Col lg={12} xs={4} md={8}>
                        <h4 className="py-4">{clientData.firstname} {clientData.lastname}
                            <span
                                class="w-10/12 text-blue-600  float-right">{age}yrs</span>
                        </h4>

                        <Tabs>
                            <TabList>
                                <Tab>Profile</Tab>
                                <Tab>Family</Tab>
                                <Tab>Appointments</Tab>
                                <Tab>Invoices</Tab>
                                <Tab>Clinical Notes</Tab>
                                <Tab>Referrals</Tab>
                                <Tab>Clinical Results</Tab>
                            </TabList>

                            <TabPanel>
                                <Container lg={12} md={12} sm={12} xs={12}>
                                    <Row xs={1} md={2} className="g-4">
                                        <Col lg={6}>
                                            <Card>
                                                <Card.Header>Personal
                                                    Information</Card.Header>
                                                <Card.Body>
                                                    <Card.Text>
                                                        <Row>
                                                            <Col lg={3}
                                                                 className="inline font-semibold text-gray-600 md:space-y-0 space-y-1 p-4 border-b">
                                                                Name
                                                            </Col>
                                                            <Col lg={9}
                                                                 className="inline text-gray-600 md:space-y-0 space-y-1 p-4 border-b">
                                                                {clientData.firstname} {clientData.lastname}
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={3}
                                                                 className="inline font-semibold text-gray-600 md:space-y-0 space-y-1 p-4 border-b">
                                                                Email
                                                            </Col>
                                                            <Col lg={9}
                                                                 className="inline text-gray-600 md:space-y-0 space-y-1 p-4 border-b">
                                                                {clientData?.user?.email}
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={3}
                                                                 className="inline font-semibold text-gray-600 md:space-y-0 space-y-1 p-4 border-b">
                                                                Dob
                                                            </Col>
                                                            <Col lg={9}
                                                                 className="inline text-gray-600 md:space-y-0 space-y-1 p-4 border-b">
                                                                {clientData?.dob}
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={3}
                                                                 className="inline font-semibold text-gray-600 md:space-y-0 space-y-1 p-4 border-b">
                                                                Home Address
                                                            </Col>
                                                            <Col lg={9}
                                                                 className="inline text-gray-600 md:space-y-0 space-y-1 p-4 border-b">
                                                                {physicalAddresses?.street_address_1} &nbsp;
                                                                {physicalAddresses?.street_address_2} &nbsp;
                                                                {physicalAddresses?.suburb} &nbsp;
                                                                {physicalAddresses?.state} &nbsp;
                                                                {physicalAddresses?.postcode}
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={3}
                                                                 className="inline font-semibold text-gray-600 md:space-y-0 space-y-1 p-4 border-b">
                                                                Postal Address
                                                            </Col>
                                                            <Col lg={9}
                                                                 className="inline text-gray-600 md:space-y-0 space-y-1 p-4 border-b">
                                                                {postalAddresses?.street_address_1} &nbsp;
                                                                {postalAddresses?.street_address_2} &nbsp;
                                                                {postalAddresses?.suburb} &nbsp;
                                                                {postalAddresses?.state} &nbsp;
                                                                {postalAddresses?.postcode}
                                                            </Col>
                                                        </Row>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col>
                                            <Card>
                                                <Card.Header>Medical
                                                    Information</Card.Header>
                                                <Card.Body>
                                                    <Card.Text>
                                                        <Row>
                                                            <Col lg={3}
                                                                 className="inline font-semibold text-gray-600 md:space-y-0 space-y-1 p-4 border-b">
                                                                Practitioner
                                                            </Col>
                                                            <Col lg={9}
                                                                 className="inline text-gray-600 md:space-y-0 space-y-1 p-4 border-b">
                                                                Dr Joe Bloggs
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={3}
                                                                 className="inline font-semibold text-gray-600 md:space-y-0 space-y-1 p-4 border-b">
                                                                Medicare Card
                                                            </Col>
                                                            <Col lg={3}
                                                                 className="inline text-gray-600 md:space-y-0 space-y-1 p-4 border-b">
                                                                23453453
                                                            </Col>
                                                            <Col lg={1}
                                                                 className="inline bg-gray-200 text-gray-600 md:space-y-0 space-y-1 py-4 border-b">
                                                                1
                                                            </Col>
                                                            <Col lg={2}
                                                                 className="inline font-semibold text-gray-600 md:space-y-0 space-y-1 py-4 border-b">
                                                                Expiry
                                                            </Col>
                                                            <Col lg={3}
                                                                 className="inline text-gray-600 md:space-y-0 space-y-1 py-4 border-b">
                                                                05/2021
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={3}
                                                                 className="inline font-semibold text-gray-600 md:space-y-0 space-y-1 p-4 border-b">
                                                                DVA Card
                                                            </Col>
                                                            <Col lg={3}
                                                                 className="inline text-gray-600 md:space-y-0 space-y-1 p-4 border-b">
                                                                23453453
                                                            </Col>
                                                            <Col lg={1}
                                                                 className="inline text-gray-600 md:space-y-0 space-y-1 py-4 border-b">

                                                            </Col>
                                                            <Col lg={2}
                                                                 className="inline font-semibold text-gray-600 md:space-y-0 space-y-1 py-4 border-b">
                                                                Expiry
                                                            </Col>
                                                            <Col lg={3}
                                                                 className="inline text-gray-600 md:space-y-0 space-y-1 py-4 border-b">
                                                                05/2021
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={3}
                                                                 className="inline font-semibold text-gray-600 md:space-y-0 space-y-1 p-4 border-b">
                                                                Health Fund #
                                                            </Col>
                                                            <Col lg={3}
                                                                 className="inline text-gray-600 md:space-y-0 space-y-1 p-4 border-b">
                                                                23453453
                                                            </Col>
                                                            <Col lg={3}
                                                                 className="inline font-semibold text-gray-600 md:space-y-0 space-y-1 py-4 border-b">
                                                                Health Fund
                                                            </Col>
                                                            <Col lg={3}
                                                                 className="inline text-gray-600 md:space-y-0 space-y-1 py-4 border-b">
                                                                AAMI
                                                            </Col>
                                                            <Col lg={3}
                                                                 className="inline font-semibold text-gray-600 md:space-y-0 space-y-1 p-4 border-b">
                                                                NDIS #
                                                            </Col>
                                                            <Col lg={3}
                                                                 className="inline text-gray-600 md:space-y-0 space-y-1 p-4 border-b">
                                                                23453453
                                                            </Col>
                                                            <Col lg={3}
                                                                 className="inline font-semibold text-gray-600 md:space-y-0 space-y-1 py-4 border-b">
                                                                NDIS Funding
                                                            </Col>
                                                            <Col lg={3}
                                                                 className="inline text-gray-600 md:space-y-0 space-y-1 py-4 border-b">
                                                                Self Managed
                                                            </Col>
                                                        </Row>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col>
                                            <Card>
                                                <Card.Header>Contact
                                                    Information</Card.Header>
                                                <Card.Body>
                                                    <Card.Text>
                                                        <Row>
                                                            <Col lg={3}
                                                                 className="inline font-semibold text-gray-600 md:space-y-0 space-y-1 p-4 border-b">
                                                                Mobile
                                                            </Col>
                                                            <Col lg={3}
                                                                 className="inline text-gray-600 md:space-y-0 space-y-1 p-4 border-b">
                                                                {clientData?.user?.mobile}
                                                            </Col>
                                                            <Col lg={3}
                                                                 className="inline font-semibold text-gray-600 md:space-y-0 space-y-1 p-4 border-b">
                                                                Home
                                                            </Col>
                                                            <Col lg={3}
                                                                 className="inline text-gray-600 md:space-y-0 space-y-1 p-4 border-b">
                                                                {clientData?.user?.mobile}
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={3}
                                                                 className="inline font-semibold text-gray-600 md:space-y-0 space-y-1 p-4 border-b">
                                                                Work
                                                            </Col>
                                                            <Col lg={3}
                                                                 className="inline text-gray-600 md:space-y-0 space-y-1 p-4 border-b">
                                                                {clientData?.user?.mobile}
                                                            </Col>
                                                            <Col lg={3}
                                                                 className="inline font-semibold text-gray-600 md:space-y-0 space-y-1 p-4 border-b">
                                                                Fax
                                                            </Col>
                                                            <Col lg={3}
                                                                 className="inline text-gray-600 md:space-y-0 space-y-1 p-4 border-b">
                                                                {clientData?.user?.mobile}
                                                            </Col>
                                                        </Row>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col>
                                            <Card>
                                                <Card.Header>Account
                                                    Details</Card.Header>
                                                <Card.Body>
                                                    <Card.Text>
                                                        <Row>
                                                            <Col lg={3}
                                                                 className="inline font-semibold text-gray-600 md:space-y-0 space-y-1 p-4 border-b">
                                                                Status
                                                            </Col>
                                                            <Col lg={3}
                                                                 className="inline text-gray-600 md:space-y-0 space-y-1 p-4 border-b">
                                                                Current
                                                            </Col>
                                                            <Col lg={3}
                                                                 className="inline font-semibold text-gray-600 md:space-y-0 space-y-1 p-4 border-b">
                                                                Verified?
                                                            </Col>
                                                            <Col lg={3}
                                                                 className="inline text-gray-600 md:space-y-0 space-y-1 p-4 border-b">
                                                                Not Verified
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={3}
                                                                 className="inline font-semibold text-gray-600 md:space-y-0 space-y-1 p-4 border-b">

                                                            </Col>
                                                            <Col lg={3}
                                                                 className="inline text-gray-600 md:space-y-0 space-y-1 p-4 border-b">
                                                                {clientData?.user?.mobile}
                                                            </Col>
                                                            <Col lg={3}
                                                                 className="inline font-semibold text-gray-600 md:space-y-0 space-y-1 p-4 border-b">
                                                                .
                                                            </Col>
                                                            <Col lg={3}
                                                                 className="inline text-gray-600 md:space-y-0 space-y-1 p-4 border-b">
                                                                {clientData?.user?.mobile}
                                                            </Col>
                                                        </Row>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>

                                </Container>
                            </TabPanel>
                                
                                <TabPanel>
                                    <div class="max-w float-left bg-white w-full rounded-lg shadow-xl">
                                        <div class="p-2 border-b bg-gradient-to-t from-gray-400 ">
                                            <h2 class="text-xl ">
                                                Family
                                                <Tippy content="Create Appointment" interactive={true} interactiveBorder={20} delay={50}>
                                                <span class="float-right text-blue-600 text-sm inline-flex">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-plus-fill" viewBox="0 0 16 16">
                                                <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                                <path fill-rule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"/>
                                                </svg>
                                                &nbsp;Add Family
                                                </span>
                                                </Tippy>
                                            </h2>
                                        </div>
                                        <div className="overflow-auto border-b border-gray-200 sm:rounded-lg">
                                        <table className="table-auto w-full overflow-auto p-2 m-1 divide-y divide-purple-400">
                                            <thead className="bg-gray-50">
                                                <tr  class="">
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >Name</th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >Relationship</th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >Email</th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >DOB</th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >Mobile</th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >Home Phone</th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >Actions</th>
                                                </tr>
                                            </thead>
                                            </table>
                                        </div>
                                    </div>
                                </TabPanel>
                        
                                <TabPanel>

                                    <div class="max-w float-left bg-white w-full rounded-lg shadow-xl">
                                        <div class="p-2 border-b bg-gradient-to-t from-gray-400 ">
                                            <h2 class="text-xl ">
                                                Appointments
                                                <Tippy content="Create Appointment" interactive={true} interactiveBorder={20} delay={50}>
                                                <span class="float-right text-blue-600 text-sm inline-flex">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar-month" viewBox="0 0 16 16">
                                                <path d="M2.56 11.332 3.1 9.73h1.984l.54 1.602h.718L4.444 6h-.696L1.85 11.332h.71zm1.544-4.527L4.9 9.18H3.284l.8-2.375h.02zm5.746.422h-.676V9.77c0 .652-.414 1.023-1.004 1.023-.539 0-.98-.246-.98-1.012V7.227h-.676v2.746c0 .941.606 1.425 1.453 1.425.656 0 1.043-.28 1.188-.605h.027v.539h.668V7.227zm2.258 5.046c-.563 0-.91-.304-.985-.636h-.687c.094.683.625 1.199 1.668 1.199.93 0 1.746-.527 1.746-1.578V7.227h-.649v.578h-.019c-.191-.348-.637-.64-1.195-.64-.965 0-1.64.679-1.64 1.886v.34c0 1.23.683 1.902 1.64 1.902.558 0 1.008-.293 1.172-.648h.02v.605c0 .645-.423 1.023-1.071 1.023zm.008-4.53c.648 0 1.062.527 1.062 1.359v.253c0 .848-.39 1.364-1.062 1.364-.692 0-1.098-.512-1.098-1.364v-.253c0-.868.406-1.36 1.098-1.36z"/>
                                                <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
                                                </svg>
                                                &nbsp;New Appointment
                                                </span>
                                                </Tippy>
                                            </h2>
                                        </div>
                                        <div className="overflow-auto border-b border-gray-200 sm:rounded-lg">
                                        <table className="table-auto w-full overflow-auto p-2 m-1 divide-y divide-purple-400">
                                            <thead className="bg-gray-50">
                                                <tr  class="">
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >Practitioner</th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >Type</th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >Location</th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >Date</th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >Client</th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >Status</th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >Actions</th>
                                                </tr>
                                            </thead>
                                            </table>
                                        </div>
                                    </div>
                                </TabPanel> 

                                <TabPanel>
                                    <div class="max-w float-left bg-white w-full rounded-lg shadow-xl">
                                        <div class="p-2 border-b bg-gradient-to-t from-gray-400 ">
                                            <h2 class="text-xl ">
                                                Invoices
                                                <Tippy content="Create Invoice" interactive={true} interactiveBorder={20} delay={50}>
                                                <span class="float-right text-blue-600 text-sm inline-flex">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar-month" viewBox="0 0 16 16">
                                                <path d="M2.56 11.332 3.1 9.73h1.984l.54 1.602h.718L4.444 6h-.696L1.85 11.332h.71zm1.544-4.527L4.9 9.18H3.284l.8-2.375h.02zm5.746.422h-.676V9.77c0 .652-.414 1.023-1.004 1.023-.539 0-.98-.246-.98-1.012V7.227h-.676v2.746c0 .941.606 1.425 1.453 1.425.656 0 1.043-.28 1.188-.605h.027v.539h.668V7.227zm2.258 5.046c-.563 0-.91-.304-.985-.636h-.687c.094.683.625 1.199 1.668 1.199.93 0 1.746-.527 1.746-1.578V7.227h-.649v.578h-.019c-.191-.348-.637-.64-1.195-.64-.965 0-1.64.679-1.64 1.886v.34c0 1.23.683 1.902 1.64 1.902.558 0 1.008-.293 1.172-.648h.02v.605c0 .645-.423 1.023-1.071 1.023zm.008-4.53c.648 0 1.062.527 1.062 1.359v.253c0 .848-.39 1.364-1.062 1.364-.692 0-1.098-.512-1.098-1.364v-.253c0-.868.406-1.36 1.098-1.36z"/>
                                                <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
                                                </svg>
                                                &nbsp;New Invoice
                                                </span>
                                                </Tippy>
                                            </h2>
                                        </div>
                                        <div className="overflow-auto border-b border-gray-200 sm:rounded-lg">
                                        <table className="table-auto w-full overflow-auto p-2 m-1 divide-y divide-purple-400">
                                            <thead className="bg-gray-50">
                                                <tr  class="">
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >Practitioner</th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >ID</th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >Contact Type</th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >Client/Contact</th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >Date</th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >Due date</th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >Status</th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                >Actions</th>
                                                </tr>
                                            </thead>
                                            </table>
                                        </div>
                                    </div>
                                </TabPanel> 
                                <TabPanel>
                                    
                                    <div class="max-w float-left bg-white w-full rounded-lg shadow-xl">

                                        <Tabs>
                                            <div class="p-2 w-full border-b bg-gradient-to-t from-gray-400 ">
                                                <div class="inline-flex w-2/12">
                                                    <h2 class="text-xl ">
                                                        Clinical Notes
                                                    </h2>
                                                </div>
                                                <div class=" inline-flex w-6/12 px-1">
                                                    
                                                        <TabList>
                                                            <Tab> 
                                                            <Tippy content="View Notes" interactive={true} interactiveBorder={20} delay={50}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chat-square-text" viewBox="0 0 16 16">
                                                                <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2.5a2 2 0 0 0-1.6.8L8 14.333 6.1 11.8a2 2 0 0 0-1.6-.8H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                                                                <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"/>
                                                                </svg>
                                                            </Tippy>
                                                            </Tab>
                                                            <Tab>
                                                            <Tippy content="New Note" interactive={true} interactiveBorder={20} delay={50}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-file-earmark-font" viewBox="0 0 16 16">
                                                                    <path d="M10.943 6H5.057L5 8h.5c.18-1.096.356-1.192 1.694-1.235l.293-.01v5.09c0 .47-.1.582-.898.655v.5H9.41v-.5c-.803-.073-.903-.184-.903-.654V6.755l.298.01c1.338.043 1.514.14 1.694 1.235h.5l-.057-2z"/>
                                                                    <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z"/>
                                                                </svg>
                                                            </Tippy>
                                                            </Tab>
                                                            <Tab onClick={() => this.handleMedical()}>
                                                            <Tippy content="Medical" interactive={true} interactiveBorder={20} delay={50}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-file-earmark-medical" viewBox="0 0 16 16">
                                                                    <path d="M7.5 5.5a.5.5 0 0 0-1 0v.634l-.549-.317a.5.5 0 1 0-.5.866L6 7l-.549.317a.5.5 0 1 0 .5.866l.549-.317V8.5a.5.5 0 1 0 1 0v-.634l.549.317a.5.5 0 1 0 .5-.866L8 7l.549-.317a.5.5 0 1 0-.5-.866l-.549.317V5.5zm-2 4.5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 2a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5z"/>
                                                                    <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
                                                                </svg>
                                                            </Tippy>
                                                            </Tab>
                                                            <Tab>
                                                            <Tippy content="New Note" interactive={true} interactiveBorder={20} delay={50}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-paperclip" viewBox="0 0 16 16">
                                                                    <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z"/>
                                                                </svg>
                                                            </Tippy>
                                                            </Tab>
                                                        </TabList>
                                                </div>
                                            </div>
                                            <div class="p-4 border-r w-full rounded-t">
                                                
                                                <TabPanel>
                                                    <ClinicalNotes />
                                                </TabPanel>
                                                <TabPanel>
                                                    <Editor />
                                                </TabPanel>
                                                <TabPanel>
                                                <Modal open={this.state.MedicalModal}
                                                    closeIcon
                                                    size='tiny'
                                                    style={{height: 400}}
                                                    onClose={this.onMedicalModalClose}
                                                >
                                                    <Modal.Header>Medical Details</Modal.Header>
                                                    <Modal.Content>
                                                        <Modal.Description>
                                                        <Form
                                                        ref={ref => this.form = ref}
                                                        onValidSubmit={this.onValidSubmit} handleChange={this.handleChange} onSubmit={this.onSubmit} success={this.state.saved === true} error={this.state.error === true}
                                                        loading={this.state.isLoading === true}
                                                        >
                                                        <div class="md:grid md:grid-cols-1 w-full">
                                                            <Form.Input
                                                                        fluid
                                                                        defaultValue=""
                                                                        width={5}
                                                                        name="BMI"
                                                                        label="BMI"
                                                                        placeholder="BMI"/>
                                                            <Form.Input name="bloodpresure"
                                                                        width={5}
                                                                        fluid
                                                                        defaultValue=""
                                                                        label="Blood Pressure"
                                                                        placeholder="Blood Pressure"/>
                                                            <Form.Input name="weight"
                                                                        width={5}
                                                                        fluid
                                                                        defaultValue=""
                                                                        label="Weight"
                                                                        placeholder="Weight"/>
                                                            <Form.Input name="heartrate"
                                                                        width={5}
                                                                        fluid
                                                                        defaultValue=""
                                                                        label="Heart Rate"
                                                                        placeholder="Heart Rate"/>
                                                        </div>
                                                        </Form>
                                                        </Modal.Description>
                                                    </Modal.Content>
                                                </Modal>
                                                </TabPanel>
                                            </div>
                                        </Tabs>
                                    </div>
                                </TabPanel>
                        </Tabs>

                    </Col>
                </Row>
            </Container>

        );
    }
}
ClientProfile.propTypes = {
    open: PropTypes.bool.isRequired,
    clientId: PropTypes.number.isRequired,
    onCancel: PropTypes.func.isRequired,
    onDeleteClient: PropTypes.func.isRequired
};
import React, {Component} from 'react'
import {ClientEP} from "../../config/ApiEndpoints";
import {ApiClientFactory} from "../../site/api/ApiClientFactory";
import {Auth} from '../../helpers/Auth.js';
import {Confirm} from 'semantic-ui-react'
import {PropTypes} from 'prop-types';
import {ApiError, AuthenticatedApiEndPoint} from "../../helpers/ApiEndPoint";

export class ClientDeleter extends Component {
    constructor(props) {
        super(props);
    }

    handleDeleteClient = () => {
        this.setState({isLoading: true});
        const api = new AuthenticatedApiEndPoint(new Auth().getJWT(),ClientEP, {clientid: this.props.clientId})
        api.delete()
            .then(response => {
                if (response.ok) {
                    this.props.onDeleteClient();
                }else{
                    ApiError(response, "Could not delete this client")
                }
            }).finally(() => {
            this.setState({isLoading: false});
        });
    };


    render() {
        return (<Confirm style={{height: 200}}
                         open={this.props.open}
                         header='Delete this client?'
                         onCancel={this.props.onCancel}
                         onConfirm={this.handleDeleteClient}
        />);
    }

}

ClientDeleter.propTypes = {
    open: PropTypes.bool.isRequired,
    clientId: PropTypes.number.isRequired,
    onCancel: PropTypes.func.isRequired,
    onDeleteClient: PropTypes.func.isRequired
};
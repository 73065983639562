import React, {Component} from 'react';
import {Redirect, withRouter} from 'react-router-dom';
import {Auth} from '../../helpers/Auth.js';
import {ApiClientFactory} from '../../site/api/ApiClientFactory';
import {User} from '../../helpers/User';
import {Grid, Typography} from '@material-ui/core';
import BusinessIcon from '@mui/icons-material/Business';

export class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logout: false,
      displayName: '',
      displayLastName: '',
      displayEmail: '',
      selectpractice: false,
    };
  }

  componentDidMount = () => {
    const auth = new Auth();
    if (auth.isClientLoggedIn()) {
      const user = new User(auth.getUserId());
      user.fetchUserData().then(response => {
        if (response.ok) {
          this.setState({
            displayEmail: response.data.email,
            displayName: response.data.firstname,
            displayLastName: response.data.lastname,
          });

        }
      });
    }
  };

  handleLogout = () => {
    new Auth().logout(ApiClientFactory.createAuthenticatedApi());
    this.setState({logout: true});
  };

  handlePracticeClick = () => {
    this.setState({'selectpractice': true});
  };

  render() {
    const auth = new Auth();
    const currentPractice = auth.getUser().getCurrentPractice();
    const currentUser = auth.getUser().getUserData();
    if (this.state.logout) {
      return <Redirect to="/login"/>;
    }
    if (this.state.selectpractice) {
      return <Redirect to="/practice-selector"/>;
    }
    return (

        <Grid container
              justify="space-around"
              align="center"
              style={{height: '100px', backgroundColor: '#0F172A'}}
              className="py-4 text-white"
        >
          <Grid item xs={12} md={12} lg={12} onClick={this.handlePracticeClick}>

            <BusinessIcon fontSize="large" className="text-white"/>
            <Typography variant="h6">
              {currentPractice.name}
            </Typography>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Typography variant="subtitle1" className="py-2 text-white">
              {currentUser.firstname} {currentUser.lastname}
            </Typography>

            {/*<Search
                placeholder="input search text"
                allowClear
                enterButton
                size="large"
              />*/}
          </Grid>
        </Grid>

    );
}
}
export default withRouter(Navbar);
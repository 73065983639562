import {AuthenticateEP, LogoutEP, TokenValidationEP, RefreshTokenEP} from "../config/ApiEndpoints";
import moment from 'moment';
import {ApiClientFactory} from "../site/api/ApiClientFactory";
import React from "react";
import _ from 'lodash';
import jwtDecode from 'jwt-decode';
import {User} from './User';

/**
 * Class to handle auth duties
 */
export class Auth {

    /**
     *
     * @param email
     * @param password
     * @param email
     * @param password
     * @param onLogIn - callback accessToken provided as param
     * @param onLoginFailed - callback - servererrorMessage provided as param
     */
    login(email, password, onLogIn, onLoginFailed) {
        const api = ApiClientFactory.createBaseApi();
        api.post(AuthenticateEP, {'email': email, 'password': password})
            .then(response => {
                if (!response.ok) {
                    onLoginFailed(_.get(response.data, 'message', "No server message supplied"));
                } else {
                    localStorage.setItem(SESSION_TOKEN_NAME, response.data.access_token);
                    setTimeout(() => {
                        onLogIn(response.data.access_token, this.getUserId());
                    }, 1500);
                }
            });
    }

    /**
     *
     * @returns {boolean}
     */
    isClientLoggedIn() {
        if (localStorage.getItem(SESSION_TOKEN_NAME)) {
            const tokenExpires = this.getTokenExpiry();
            if (tokenExpires) {
                const expiry = moment.unix(tokenExpires);
                return expiry.isValid() && expiry.isAfter(moment());
            }
            return false;
        }
    }

    /**
     *
     * @param onIsLoggedIn
     * @param onIsNotLoggedIn
     */
    isServerLoggedIn(onIsLoggedIn, onIsNotLoggedIn) {

        if (!this.isClientLoggedIn()) {
            return false;
        }

        const api = ApiClientFactory.createBaseApi();
        api.get(TokenValidationEP)
            .then(response => {
                if (response.ok && response.data == true) {
                    onIsLoggedIn()
                } else {
                    onIsNotLoggedIn();
                }
            });
    }


    async isLoggedInAsync() {

        if (!this.isClientLoggedIn()) {
            return false;
        }

        const api = ApiClientFactory.createBaseApi();
        const data = await api.get(TokenValidationEP);
        return data.status;

    }

    /**
     *
     * @param authenticatedApi
     * @param onLogout
     * @param onError
     */
    logout(authenticatedApi, onLogout, onError) {
        authenticatedApi.post(LogoutEP)
            .then(response => {
                if (!response.ok) {
                    onError("There was an error logging out");
                } else {
                    onLogout();
                }

            }).finally(() => {
            this.logoutClient(() => {
            });
        });
    }

    /**
     * Clear the localstorages
     * @param onLogout
     */
    logoutClient(onLogout) {
        localStorage.removeItem(SESSION_TOKEN_NAME);
        setTimeout(() => {
            onLogout();
        }, 200);


    }

    /**
     * Get the JWT (jsonwebtoken) returned from the server
     * @returns {string}
     */
    getJWT() {
        return localStorage.getItem(SESSION_TOKEN_NAME);
    }

    /**
     *
     * @returns {any}
     */
    getDecodedJWT() {
        return jwtDecode(this.getJWT());
    }

    /**
     * Get the permissions from the token
     * @returns {*}
     */
    getPermissions() {
        const token = this.getDecodedJWT();
        return token.scopes;
    }

    /**
     *
     * @returns {*}
     */
    getUserId() {
        const token = this.getDecodedJWT();
        return token.sub;
    }

    /**
     *
     * @returns {User}
     */
    getUser() {
        return new User(this.getUserId());
    }

    /**
     *
     */
    getTokenExpiry() {
        const token = this.getDecodedJWT();
        return token.exp;
    }


    /**
     * Checks if the token has the given perm (if the token has all perms *
     * returns true)
     * @param permission
     * @returns {boolean}
     */
    hasPermission(permission) {
        const perms = this.getPermissions();
        if (Array.isArray(perms)) {
            return perms.indexOf(permission) != -1 || perms.indexOf('*') != -1;
        }
        return false;
    }
}

export const SESSION_TOKEN_NAME = "jwtToken";

export const Permissions = {
    owner: 'owner',
    client: 'client',
    practitioner: 'practiitoner',
    office_admin: 'office admin'
};

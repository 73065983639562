import React, {Component} from 'react';
import {PracticesEP} from '../../../config/ApiEndpoints';
import {Auth} from '../../../helpers/Auth.js';
import {Modal, Pagination} from 'semantic-ui-react';
import {PracticeEdit} from '../PracticeEdit';
import {PracticeCopy} from '../PracticeCopy';
import {PracticeCreate} from '../PracticeCreate';
import {PracticeDeleter} from '../PracticeDeleter';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import {ApiError, AuthenticatedApiEndPoint} from '../../../helpers/ApiEndPoint';
import '../../../styles/output.css';
import 'tippy.js/dist/tippy.css';
import SearchBar from '../../../components/helpers/searchbox/SearchBar';
import {
  Button,
  Col,
  Container,
  OverlayTrigger,
  Row,
  Spinner,
  Table,
} from 'react-bootstrap';
import {
  copyRecord,
  deleteRecord,
  editDetails,
  newPractice,
  showAppointments,
  showProfile,
} from '../../helpers/popovers/PopOvers';

export class LocationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      practiceData: [],
      locations: [],
      practice: [],
      isLoading: false,
      editPracticeModal: false,
      deletePractice: undefined,
      syncingPractice: false,
      activePage: 1,
      totalPages: 1,
      practice_name: undefined,
      street_address_1: null,
      location: null,
      sort: null,
      filter: null,
    };
    this.filterDebouncer = AwesomeDebouncePromise(this.loadLocations, 200);
    }

    componentWillMount() {
      this.loadLocations();
    }

  loadLocations = () => {
    this.setState({isLoading: true});
    const endPoint = new AuthenticatedApiEndPoint(new Auth().getJWT(),
        PracticesEP);
    endPoint.get({
      ...(this.state.filter ? {} : {page: this.state.activePage}),
      sort: 'practice_name',
      'filter[practice_name]=': this.state.filter,
    }).then(response => {
      if (response.ok) {
        this.setState({
          'practice': response.data.data,
          totalPages: response.data.meta.last_page,
        });
        let locations = [];
        for (const practice of response.data.data) {
          practice.locations.forEach(e => e.practice = practice.practice_name);
          if (practice.locations.length > 0) {
            locations = locations.concat(practice.locations);
          }
        }
        this.setState({'locations': locations});
      }
      else {
        ApiError(response, 'Could not retrieve the locations');
      }
    }).finally(() => {
      this.setState({isLoading: false});
    });
  };

    handlePaginationChange = (e, {activePage}) => {
      this.setState({activePage}, this.loadLocations);
    };

    handleFilterChange = async (e) => {
        const {value} = e.target
        this.setState({filter: value}, () => {
            this.filterDebouncer()
            //this.loadClients()
        });
    }

    onPracticeAdded = () => {
        this.onModalClose();
        this.loadPractices();

    };

    onPracticeDeleted = (data) => {
        this.loadPractices();
        this.setState({deletePractice: null});
    };

    onPracticeSyncronised = () => {
        this.setState({syncronisePractice: null});
    };

    handleDeletePractice = (data) => {
        this.setState({deletePractice: data.id});
    };

    handleSyncronisePractice = (data) => {
      this.setState({syncronisePractice: data.id});
    };

  handleEditPractice = (data) => {
    this.setState({editPractice: data, editPracticeModal: true});
  };

  onModalClick = () => this.setState(
      {editPracticeModal: true, editPractice: false});
  onModalClose = () => this.setState(
      {editPracticeModal: false, editPractice: false});

  handleCreatePractice = () => {
    this.setState({createPracticeModal: true});
  };

  onCreateModalClick = () => this.setState(
      {createPracticeModal: true, createPractice: false});
  onCreateModalClose = () => this.setState(
      {createPracticeModal: false, createPractice: false});

  render() {

    const practice = this.state.practice;
    const auth = new Auth();
    return (
        <Container fluid
                   className="p-2 min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
          <Row>
            <Col lg={8} xs={4} md={8}>
              <h1 className="text-2xl p-2">Practices</h1>
            </Col>

            <Col lg={4} xs={4} md={4}>
              <OverlayTrigger
                  placement="top"
                  delay={{show: 250, hide: 400}}
                  overlay={newPractice}
              >
                <div class="float-right">
                  <Button onClick={() => this.handleCreatePractice()}
                          variant="secondary">
                    New Practice
                  </Button>
                </div>
              </OverlayTrigger>
            </Col>

          </Row>
          <Row>
            {this.state.isLoading === true &&
            <Col lg="auto" xs={2} md={4}>

              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>

            </Col>
            }
            <Col lg={8} xs={12} md={12}>
              {this.state.totalPages > 1 &&
              <Pagination
                  defaultActivePage={1}
                  totalPages={this.state.totalPages}
                  onPageChange={this.handlePaginationChange}
                  pointing
                  secondary
              />
              }
            </Col>
          </Row>
          <Row>
            <Col lg={12} xs="auto" md="auto">
              <Table responsive
                     className="table-auto w-full overflow-auto  m-1 divide-y divide-purple-400">
                <thead className="bg-gray-50">
                <tr>
                  <th
                      scope="col"
                      className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >Practice
                  </th>
                  <th
                      scope="col"
                      className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >Locations
                  </th>
                  <th colspan="3"
                      scope="col"
                      className="px-2 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >Actions
                  </th>
                </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                <tr>
                  <td>
                    <SearchBar
                        handleFilterChange={this.handleFilterChange}
                        filter={this.state.filter}
                    />
                  </td>
                </tr>
                {practice.map((data) => {
                  return <tr key={data.id}>
                    <td className="px-2 py-4">
                      <Row>
                        <Col
                            className="py-1 items-center text-sm font-medium text-gray-900 fas fa-hospital">
                          &nbsp;{data.practice_name}
                        </Col>
                      </Row>
                      <Row>
                        <Col
                            className="fas fa-user py-1 px-3 text-muted items-center text-sm font-medium text-gray-200">
                          &nbsp;{data.owner?.firstname} {data.owner?.lastname}
                        </Col>
                      </Row>
                    </td>
                    <td className="px-2 py-4 whitespace-nowrap">
                      {data.locations.map((location) => {
                        if (data.id === location.practice_id) {
                          return <tr key={location.id}>
                            <Col
                                className="text-sm font-medium text-gray-900 items-center">
                              {location.location != null &&
                              <span
                                  className="p-1 text-sm font-medium text-gray-900">
                                            {location.location} 
                                        </span>}
                              {location.location == null &&
                              <span
                                  className="p-1 text-sm font-medium text-gray-900">
                                            No locations available
                                        </span>}
                              {location.address?.street_address_1 != null &&
                              <span>
                                            - &nbsp;
                                {location.address?.street_address_1},&nbsp;
                                {location.address?.street_address_2},&nbsp;
                                {location.address?.suburb},&nbsp;
                                {location.address?.state},&nbsp;
                                {location.address?.postcode},&nbsp;
                                        </span>}
                            </Col>
                            <Row>
                              <Col
                                  className="py-2 px-5 items-center text-muted text-wrap text-sm font-medium text-gray-300">
                                &nbsp;{location.details}
                              </Col>
                            </Row>

                          </tr>;
                        }
                      })}
                    </td>
                    <td className="px-1 py-4 whitespace-nowrap">
                      <Row>
                        <Col className="p-1">
                          <OverlayTrigger
                              placement="top"
                              delay={{show: 250, hide: 400}}
                              overlay={showAppointments}
                          >
                            <a onClick={() => this.handleCreateLocation(data)}
                               className="text-sm font-medium text-orange-600 cursor-pointer text-decoration-none  d-flex fas fa-calendar-alt">
                              <span>&nbsp;Create Location</span>
                            </a>
                          </OverlayTrigger>
                        </Col>
                        <Col className="p-1">
                          <OverlayTrigger
                              placement="top"
                              delay={{show: 250, hide: 400}}
                              overlay={deleteRecord}
                          >
                            <a onClick={() => this.handleDeletePractice(data)}
                               className="text-sm font-medium text-red-800 cursor-pointer text-decoration-none  d-flex fas fa-trash">
                              <span>&nbsp;Delete Practice</span>
                            </a>
                          </OverlayTrigger>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="p-1">
                          <OverlayTrigger
                              placement="top"
                              delay={{show: 250, hide: 400}}
                              overlay={showProfile}
                          >
                            <a onClick={() => this.handleShowPractice(data)}
                               className="text-sm font-medium text-green-900 cursor-pointer text-decoration-none  d-flex fas fa-user">
                              <span>&nbsp;Show Profile</span>
                            </a>
                          </OverlayTrigger>
                        </Col>
                        <Col className="p-1">
                          <OverlayTrigger
                              placement="top"
                              delay={{show: 250, hide: 400}}
                              overlay={copyRecord}
                          >
                            <a onClick={() => this.handleCopyPractice(data)}
                               className="text-sm font-medium text-gray-900 cursor-pointer text-decoration-none d-flex fas fa-calendar-alt">
                              <span>&nbsp;Copy Practice</span>
                            </a>
                          </OverlayTrigger>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="p-1">
                          <OverlayTrigger
                              placement="top"
                              delay={{show: 250, hide: 400}}
                              overlay={editDetails}
                          >
                            <a onClick={() => this.handleEditPractice(data)}
                               className="text-sm font-medium text-decoration-none text-bluegray-700 cursor-pointer d-flex fas fa-calendar-alt">
                              <span>&nbsp;Edit Profile</span>
                            </a>
                          </OverlayTrigger>
                        </Col>
                      </Row>
                    </td>
                  </tr>;
                })
                }
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col
                className="py-2 align-middle page-wrapper inline-block min-w-full sm:px-6 lg:px-8">
              {this.state.deletePractice &&
              <PracticeDeleter practiceId={this.state.deletePractice}
                               open={Number.isInteger(
                                   this.state.deletePractice)}
                               onCancel={() => this.setState(
                                   {deletePractice: null})}
                               onDeletePractice={this.onPracticeDeleted}/>
              }

              {this.state.showPractice &&
              this.props.history.push('/practiceP',
                  {practiceId: this.state.showPractice})
                //<PracticeProfile practiceId={this.state.showPractice}
                // open={(this.state.showPractice)} onCancel={() =>
                // this.setState({showPractice: null})}/>
              }

              {this.state.editPracticeModal &&
              <Modal open={this.state.editPracticeModal}
                     closeIcon
                     size="medium"
                  //style={{height: 400}}
                  //dimmer='blurring'
                  //style={{ marginBottom: 10, height: 400 }}
                     onClose={this.onModalClose}
              >
                <Modal.Header>Edit Practice</Modal.Header>
                <Modal.Content>
                  <Modal.Description>
                    <PracticeEdit data={this.state.editPractice}
                                  onCancel={this.onModalClose}
                                  onPracticeSaved={this.onPracticeAdded}/>
                  </Modal.Description>
                </Modal.Content>
              </Modal>
              }

              {this.state.copyPracticeModal &&
              <Modal open={this.state.copyPracticeModal}
                     closeIcon
                     size="tiny"
                     style={{height: 400}}
                     onClose={this.onCopyModalClose}
              >
                <Modal.Header>Copy Practice</Modal.Header>
                <Modal.Content>
                  <Modal.Description>
                    <PracticeCopy data={this.state.copyPractice}
                                  onCancel={this.onCopyModalClose}
                                  onPracticeSaved={this.onPracticeAdded}/>
                  </Modal.Description>
                </Modal.Content>
              </Modal>
              }

              {this.state.createPracticeModal &&
              <Modal open={this.state.createPracticeModal}
                     closeIcon
                     size="medium"
                     onClose={this.onCreateModalClose}
              >
                <Modal.Header>Create Practice</Modal.Header>
                <Modal.Content>
                  <Modal.Description>
                    <PracticeCreate data={this.state.createPractice}
                                    onCancel={this.onCreateModalClose}
                                    onPracticeSaved={this.onPracticeAdded}/>
                  </Modal.Description>
                </Modal.Content>
              </Modal>
              }
            </Col>
          </Row>
        </Container>
           
        );
            }}
import React, {Component} from 'react';
//import {Form} from 'formsy-semantic-ui-react'
import {Form, Header} from 'semantic-ui-react';
import 'react-datepicker/dist/react-datepicker.css';
import {PracticesEP} from '../../../config/ApiEndpoints';
import PropTypes from 'prop-types';
import {apiRequest} from '../../../api/psy/ApiRequest';

/**
 *
 */
export class LocationsDropdown extends Component {

  constructor(props) {
    super(props);
    this.state = {
      locations: [], isLoading: false,
    };
  }

  componentDidMount = () => {
    this.load();
  };

  load(searchQuery) {
    this.setState({isLoading: true});

    apiRequest(PracticesEP,
        null,
        {'filter[id]': this.props.practice, 'filter[lastname]': searchQuery},
        (data) => {
          let locations = [];
          for (const practice of data) {
            practice.locations.forEach(
                e => e.practice = practice.practice_name);
            if (practice.locations.length > 0) {
              locations = locations.concat(practice.locations);
            }
          }
          this.setState({'locations': locations});
        },
        () => this.setState({isLoading: false}));
  };

  findLocation = (id) => {
    return this.state.locations.find(item => item.id == id);
  };

  /**
   * If we've recieved a non-int id, (e.g My Unit Type) it's a manually entered
   * value this checks if a dropdown value is an id or not
   * @param value
   */
  isSelectionManual = (value) => {
    return isNaN(parseFloat(value)) || !isFinite(value);
  };

  handleChange = (e, {value}) => {
    const location = this.findLocation(value);
    this.props.onSelect(location);
  };

  handleInputChange = (e, {searchQuery}) => {
    this.load(searchQuery);
  };

  render() {
    const options = this.state.locations.map((value, index) => {
      return ({
        key: value.id,
        text: value.practice + ' - ' + value.location,
        description: value.location,
        value: value.id,
        //content: (
        //  <Header icon='mobile' content={value.location}
        // subheader={value.practice} /> ),
      });
    });

    return (
        <Form.Dropdown
            options={options}
            onSearchChange={this.handleInputChange}
            search
            placeholder="Select Location"
            selection
            onChange={this.handleChange}
        />

    );
  }
}

const formatOptionLabel = ({value, text, description}) => (
    <Header size="tiny" content={text} subheader={description}/>
);

LocationsDropdown.propTypes = {
  onSelect: PropTypes.func.isRequired, // function that handles client dropdown
                                       // selection
  defaultSelection: PropTypes.any, //selected value of the client dropdown
  width: PropTypes.number,
  name: PropTypes.string.isRequired,
  practice: PropTypes.number,
};

import React from "react"
import {toast} from "react-semantic-toasts";

/**
 * Display a toast notification
 * @param type
 * @param title
 * @param message
 * @param onClose
 * @param onClick
 * @param time
 */
export function DisplayNotification(type, title, message, onClose, onClick, time = 8000){
    const config = {
        type: type,
        title: title,
        description: <p>{message}</p>,
        animation: 'fly in',
        time: time,
        onClose: onClose,
        onClick: onClick,
        size: "large"
    }

    switch(type){
        case "warning":
            break;
        case "error":
            config.icon = "exclamation"
            break;
        case "success":
            if(!title) {
                config.title = "Success!";
            }
            break;

    }
    toast(config);
}
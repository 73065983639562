import React from 'react';
import {Form} from 'formsy-semantic-ui-react';
import PropTypes from 'prop-types';

export default class AddressInputGroup extends React.Component {
  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      openTab: null,
      setOpenTab: null,
      street_address_1: null,
      street_address_2: null,
      fullAddress: null,
      suburb: null,
      state: null,
      postcode: null,
    };
    this.widget = null;
    this.widgetId = Math.random();
    this.address_line_1 = React.createRef();
    this.address_line_2 = React.createRef();
    this.suburb = React.createRef();
    this.state = React.createRef();
    this.postcode = React.createRef();

  }

  componentDidMount() {
    var script = document.createElement('script');
    script.src = 'https://api.addressfinder.io/assets/v3/widget.js';
    //script.async = true;
    script.onload = () => {
      this.loadWidget();
    };
    document.body.appendChild(script);

    if (this.widget) {
      this.widget.destroy();
      this.widget = null;
    }
  }

  
  onChange = (e) => {
    const {name, value} = e.target;
    this.setState({[name]: value}, () => this.props.onChange(this.state));

  };

  loadWidget = () => {
    this.widget = new window.AddressFinder.Widget(
        document.getElementById(this.widgetId),
        '4MBXYTWFPAN73R89VCEJ',
        'AU',
    );
    this.widget.on('result:select', (fullAddress, metaData) => {
      this.setState({
        fullAddress: metaData.full_address,
        street_address_1: metaData.address_line_1,
        street_address_2: metaData.address_line_2,
        suburb: metaData.locality_name,
        state: metaData.state_territory,
        postcode: metaData.postcode,
      }, () => this.props.onChange(this.state));
    });
  };

  render() {
    const {state: {resi}} = this;
    return (
        <div class="md:grid md:grid-cols-1 w-full">
          <div class="md:grid md:grid-cols-1 w-full">
            <div class="required fifteen wide field">

              {this.props.address === undefined &&
              <Form.Input
                  fluid
                  name="fullAddress"
                  id={this.widgetId}
                  ref={this.fullAddress}
                  placeholder="Start typing address"
                  onChange={this.handleChange}
                  onFocus={this.loadWidget}
                  value={this.state.fullAddress}
              />
              }

              {this.props.address &&
              this.props.address.street_address_1 !== undefined &&
              <Form.Input
                  fluid
                  name="fullAddress"
                  id={this.widgetId}
                  placeholder={[
                    this.props.address.street_address_1 + ', ' +
                    this.props.address.suburb + ', ' +
                    this.props.address.state + ', ' +
                    this.props.address.postcode]}
                  onFocus={this.loadWidget}
                  value={this.state.fullAddress} 
                  onChange={this.onChange}
                  ref={this.fullAddress}
              />

              }

              <Form.Input
                  fluid
                  name="street_address_1"
                  id="street_address_1"
                  type="hidden"
                  ref={this.address_line_1}
                  onChange={this.onChange}
                  value={this.state.street_address_1}
              />

              <Form.Input
                  fluid
                  name="street_address_2"
                  id="street_address_2"
                  type="hidden"
                  ref={this.address_line_2}
                  onChange={this.onChange}
                  value={this.state.street_address_2}
              />

              <Form.Input
                  fluid
                  name="suburb"
                  id="suburb"
                  type="hidden"
                  ref={this.suburb}
                  onChange={this.onChange}
                  value={this.state.suburb}
              />

              <Form.Input
                  fluid
                  name="state"
                  id="state"
                  type="hidden"
                  ref={this.state}
                  onChange={this.onChange}
                  value={this.state.state}
              />

              <Form.Input
                  fluid
                  name="postcode"
                  id="postcode"
                  type="hidden"
                  ref={this.postcode}
                  onChange={this.onChange}
                  value={this.state.postcode}
              />

            </div>
          </div>

        </div>
    );
  }
}



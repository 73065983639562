import moment from 'moment';

/**
 * Left to right, will return the first valid date given as args
 *
 * @example
 * getFirstValidDateAsDate(null,"","2009-03-04","2006-03-04") will return a
 *     Date Object as 2009-03-04
 *
 * @param dates
 * @returns {null|void}
 */
export function getFirstValidDateAsDate(...dates){
    for (var fieldIndex = 0; fieldIndex < dates.length; fieldIndex ++) {
        if(!dates[fieldIndex]){
            continue;
        }
        const date = moment(dates[fieldIndex]);
        if(date.isValid()){
            return date.toDate();
        }
    }
    return null;
}

/**
 * Return the date formatted or "N/A" if no date (invalid)
 *
 * @param tryDate
 * @param format
 * @returns {string}
 * @constructor
 */
export function NiceTextDate(tryDate, format = 'DD/MM/YYYY') {
    let date = getFirstValidDateAsDate(tryDate);
    if (date !== null) {
        return moment(date).format(format);
    }
    else {
        return 'N/A';
    }
}

export function generateTimes(selectedTime) {
    var m = (Math.round(selectedTime.minutes() / 15) * 15) % 60;
    selectedTime.minutes(m + 15);
    selectedTime = selectedTime.format('h:mm a');

    const timeOptions = [];
    for (let z = 0; z < 24; z++) {
        for (let i = 0; i < 60; i += 15) {
            const time = (z <= 12 ? z : z - 12) + ':' + (i ? i : '00') +
                (z < 12 ? ' am' : ' pm');
            timeOptions.push({
                key: time,
                value: time,
                text: time,
                selected: (selectedTime == time ? true : false),
            });
        }
    }
    return timeOptions;
}
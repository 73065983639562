import React, {Component} from 'react'
import {UserEP} from "../../config/ApiEndpoints";
import {Auth} from '../../helpers/Auth.js';
import {Confirm} from 'semantic-ui-react'
import {PropTypes} from 'prop-types';
import {ApiError, AuthenticatedApiEndPoint} from "../../helpers/ApiEndPoint";

export class UserDeleter extends Component {
    constructor(props) {
        super(props);
    }

    handleDeleteUser = () => {
        this.setState({isLoading: true});
        const api = new AuthenticatedApiEndPoint(new Auth().getJWT(),UserEP, {userid: this.props.userId})
        api.delete()
            .then(response => {
                if (response.ok) {
                    this.props.onDeleteUser();
                }else{
                    ApiError(response, "Could not delete this user")
                }
            }).finally(() => {
            this.setState({isLoading: false});
        });
    };


    render() {
        return (<Confirm style={{height: 200}}
                         open={this.props.open}
                         header='Delete this user?'
                         onCancel={this.props.onCancel}
                         onConfirm={this.handleDeleteUser}
        />);
    }

}

UserDeleter.propTypes = {
    open: PropTypes.bool.isRequired,
    userId: PropTypes.number.isRequired,
    onCancel: PropTypes.func.isRequired,
    onDeleteUser: PropTypes.func.isRequired
};
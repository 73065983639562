import React, {Component} from 'react';
//import { Segment, Header, Grid} from 'semantic-ui-react';
import {Auth} from '../../helpers/Auth.js';
import {Container, Grid} from '@mui/material/';
import {AppointmentsTable} from '../appointments/AppointmentsTable';

export class Dashboard extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const auth = new Auth();
    return (

        <Grid lg={12} md={12} xs={12}>
          <Grid item lg={9}
                className="overflow-auto border-b border-gray-200 sm:rounded-lg">
            <AppointmentsTable/>
          </Grid>
          <Grid container lg={3}>
            <Grid item>
              {/*<Header as="h2">Activity</Header>
                <DashCards></DashCards>
                <Header as="h2">Notifications</Header>
                <Content>
                  <NotificationsFeed/>
                </Content> */}
            </Grid>
          </Grid>
        </Grid>

    );
    }
}
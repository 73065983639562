import React, {Component} from 'react'
import {ApiClientFactory} from "../../site/api/ApiClientFactory";
import {PracticeProductsEP} from "../../config/ApiEndpoints";
import {Auth} from '../../helpers/Auth.js';
import {Modal, Pagination} from 'semantic-ui-react'
import { differenceInCalendarYears, format, endOfToday } from 'date-fns'
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import {ApiError, AuthenticatedApiEndPoint} from "../../helpers/ApiEndPoint";
import _ from 'lodash';
import '../../styles/output.css'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; 
import SearchBar from "../helpers/searchbox/SearchBar";

export class ServiceList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            servicesData: [],
            isLoading: false
        };
        this.filterDebouncer = AwesomeDebouncePromise(this.loadClients, 200);
    }

    componentDidMount() {
        this.loadServices();
    }

    loadServices = () => {
        this.setState({isLoading: true});
        const endPoint = new AuthenticatedApiEndPoint(new Auth().getJWT(), PracticeProductsEP);
        endPoint.get(//{
            //... (this.state.filter ? {} : {page: this.state.activePage}),
            //sort: 'lastname',
            //'filter[lastname]=': this.state.filter
            //})
            )
            .then(response => {
                if (response.ok) {
                    this.setState({servicesData: response.data.data, totalPages: response.data.meta.last_page});
                }
            })
            .finally(() => {
            this.setState({isLoading: false});
        })
    }
    render() {
    const servicesData = this.state.servicesData;
        const auth = new Auth();
        return (
            <div className="flex flex-col">

                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="overflow-auto border-b border-gray-200 sm:rounded-lg">
                            <div className="inline-flex w-full stretched column">
                                <div>
                                <h1 className="text-2xl">Services</h1>
                                </div>
                            </div>
                            <div className=" w-full stretched column">
                                <div className="w-full stretched column float-right">
                                    {this.state.totalPages > 1 &&
                                        <Pagination
                                            defaultActivePage={1}
                                            totalPages={this.state.totalPages}
                                            onPageChange={this.handlePaginationChange}
                                            pointing
                                            secondary
                                        />
                                    }

                                <Tippy content="Add a new client" interactive={true} interactiveBorder={20} delay={50}>
                                <button  onClick={() => this.handleCreateClient()} class="text-sm font-medium bg-blue-400 hover:bg-blue-100 py-2 px-4 border border-gray-200 rounded inline-flex items-center float-right">
                                    New Service
                                </button>
                                </Tippy>
                                </div>
                            </div>
                            <table className="table-auto w-full overflow-auto p-2 m-1 divide-y divide-purple-400">
                                <thead className="bg-gray-50">
                                    <tr  class="">
                                    <th
                                        scope="col"
                                        className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >Service Name
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >Rate</th>
                                    <th
                                        scope="col"
                                        className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >Item Number</th>
                                    <th
                                        scope="col"
                                        className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >Description</th>
                                    <th
                                        scope="col"
                                        className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >Duration</th>
                                    <th
                                        scope="col"
                                        className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >Taxable?</th>
                                    <th colspan="3"
                                        scope="col"
                                        className="px-2 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >Actions</th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                <tr>
                                    <td>
                                        <SearchBar 
                                        handleFilterChange={this.handleFilterChange}
                                        filter={this.state.filter}
                                        />
                                    </td>
                                </tr>
                                {servicesData.sort((a, b) => a.lastname > b.lastname ? 1 : -1).map((data) => {
                            return <tr key={data.id} >
                                        <td className="px-2 py-4 whitespace-nowrap">
                                            <div className="flex items-center">
                                                <div className="flex-shrink-0 h-10 w-5">
                                                    {data.id}
                                                </div>
                                            </div>
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>

                                        </td>
                                        <td className="px-1 py-4 whitespace-nowrap">
                                            <div className="flex items-center">
                                                <div className="ml-0">
                                                    <div className="text-sm font-medium text-gray-900">
                                                        <Tippy content="Edit Profile" interactive={true} interactiveBorder={20} delay={50}>
                                                        <a onClick={() => this.handleEditClient(data)} class="text-sm font-medium cursor-pointer hover:underline text-grey-100 py-2 px-2 inline-flex items-center">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="blue" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                                            <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                                            </svg>
                                                            <span>&nbsp;Edit Client</span>
                                                        </a>
                                                        </Tippy>
                                                    </div>
                                                    <div className="text-sm font-medium text-gray-900">
                                                        <Tippy content="Delete Client" interactive={true} interactiveBorder={20} delay={50}>
                                                        <a onClick={() => this.handleDeleteClient(data)} class="text-sm font-medium cursor-pointer hover:underline text-grey-100 py-2 px-2 inline-flex items-center">
                                                        <   svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-x-circle" viewBox="0 0 16 16">
                                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                                            </svg>
                                                            <span>&nbsp;Delete Client</span>
                                                        </a>
                                                        </Tippy>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )}
}
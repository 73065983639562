import React from 'react';
import PropTypes from 'prop-types';
import AddressInputGroup from './AddressInputGroup';

class AddressFinder extends React.Component {
  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      physical: {
        street_address_1: null,
        street_address_2: null,
        fullAddress: null,
        suburb: null,
        state: null,
        postcode: null,
      },
      postal: {
        street_address_1: null,
        street_address_2: null,
        fullAddress: null,
        suburb: null,
        state: null,
        postcode: null,
      },
    };

  }

  onPhysicalChange = (values) => {
    this.setState({physical: values},
        () => this.props.onPhysicalChange(this.state));
  };

  onPostalChange = (values) => {
    this.setState({postal: values},
        () => this.props.onPostalChange(this.state));
  };

  render() {
    const {state: {resi}} = this;
    //const {state: { post }} = this;
    return (
        <div class="md:grid md:grid-cols-2 w-full">
          <div class="md:grid md:grid-cols-1 w-full">
            <div class="required seventeen wide field">
              <label>Residential Address</label>
              <AddressInputGroup address={this.props.physicalAddresses[0]}
                                 onChange={this.onPhysicalChange}/>

            </div>
          </div>

          <div class="md:grid md:grid-cols-1 w-full">
            <div class="required seventeen wide field">
              <label>Postal Address</label>

              <AddressInputGroup address={this.props.postalAddresses[0]}
                                 onChange={this.onPostalChange}/>
            </div>
          </div>
        </div>
    );
  }
}

export default AddressFinder;
AddressFinder.propTypes = {
  clientData: PropTypes.object,

};

import React, {Component} from 'react'
import {CompaniesEP} from "../../config/ApiEndpoints";
import {Auth} from '../../helpers/Auth.js';
import {Confirm} from 'semantic-ui-react'
import {PropTypes} from 'prop-types';
import {ApiError, AuthenticatedApiEndPoint} from "../../helpers/ApiEndPoint";

export class PracticeDeleter extends Component {
    constructor(props) {
        super(props);
    }

    handleDeletePractice = () => {
        this.setState({isLoading: true});
        const api = AuthenticatedApiEndPoint(new Auth().getJWT(),CompaniesEP, {practiceid: this.props.practiceId})
        api.delete()
            .then(response => {
                if (response.ok) {
                    this.props.onDeletePractice();
                }else{
                    ApiError(response, "Could not delete this practice")
                }
            }).finally(() => {
            this.setState({isLoading: false});
        });
    };


    render() {
        return (<Confirm style={{height: 200}}
                         open={this.props.open}
                         header='Delete this practice?'
                         onCancel={this.props.onCancel}
                         onConfirm={this.handleDeletePractice}
        />);
    }

}

PracticeDeleter.propTypes = {
    open: PropTypes.bool.isRequired,
    practiceId: PropTypes.number.isRequired,
    onCancel: PropTypes.func.isRequired,
    onDeletePractice: PropTypes.func.isRequired
};
import React from "react";
import {Auth} from "./Auth";
import {Route, Redirect} from 'react-router-dom';

/**
 *
 * Private router checks if a user is logged in.  Wraps the route component, accepts component or render props
 *
 * @param Component
 * @param render
 * @param rest
 * @returns {*}
 * @constructor
 */
export const PrivateRoute = ({component: Component, render, ...rest}) => {

    const renderContent = props => {
        if(!new Auth().isClientLoggedIn() === true){
            return (<Redirect to="/login"/>);
        }
        return (typeof render === 'function') ? render(props) : <Component {...props} />
    }

    return(
        <Route {...rest} render={renderContent} />
    );
};
import React, {Component} from 'react';
import {PractitionersEP} from '../../config/ApiEndpoints';
import {Auth} from '../../helpers/Auth.js';
import {PractitionerListView} from '../../views/PractitionerListView';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import '../../styles/output.css';
import 'tippy.js/dist/tippy.css';
import {apiRequest} from '../../api/psy/ApiRequest';
import {getSessionPracticeId} from '../../helpers/Session';

export class PractitionerList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            practitionerData: [],
            isLoading: false,
            editPractitionerModal: false,
            deletePractitionerModel: false,
            deletePractitioner: undefined,
            showPractitioner: undefined,
            syncingPractitioner: false,
            syncronisePractitioner: null,
            addressData: [],
            postalAddresses: [],
            physicalAddresses: [],
            maxPhysicalId: [],
            activePage: 1, 
            totalPages: 1, 
            lastname: undefined,
            sort: null,
            filter: null
        };
        this.filterDebouncer = AwesomeDebouncePromise(this.loadPractitioners, 200);
    }

    componentWillMount() {
        this.loadPractitioners();
    }

    loadPractitioners = () => {
        this.setState({isLoading: true});

        apiRequest(PractitionersEP, getSessionPracticeId(), {
            ...(this.state.filter ? {} : {page: this.state.activePage}),
            sort: 'lastname',
            'filter[lastname]=': this.state.filter,
        }, (data, meta) => {
            this.setState({practitionerData: data, totalPages: meta.last_page});
        }, () => this.setState({isLoading: false}));
    };

    handlePaginationChange = (e, {activePage}) => {
        this.setState({activePage}, this.loadPractitioners)
    };

    handleFilterChange = async (e) => {
        const {value} = e.target
        this.setState({filter: value}, () => {
            this.filterDebouncer()
            //this.loadPractitioners()
        });
    }

    onPractitionerAdded = () => {
        this.onModalClose();
        this.loadPractitioners();
    }

    onPractitionerDeleted = (data) => {
        this.loadPractitioners();
        this.setState({deletePractitioner: null});
    };

    onPractitionerSyncronised = () => {
        this.setState({syncronisePractitioner: null});
    };

    handleDeletePractitioner = (data) => {
        this.setState({deletePractitioner: data.id});
    };

    handleSyncronisePractitioner = (data) => {
        this.setState({syncronisePractitioner: data.id});
    };

    handleEditPractitioner = (data) => {
        this.setState({editPractitioner: data, editPractitionerModal: true});
    };

    onModalClick = () => this.setState({editPractitionerModal: true, editPractitioner: false});
    onModalClose = () => this.setState({editPractitionerModal: false, editPractitioner: false});

    handleCopyPractitioner = (data) => {
        this.setState({copyPractitioner: data, copyPractitionerModal: true});
    };

    onCopyModalClick = () => this.setState({copyPractitionerModal: true, copyPractitioner: false});
    onCopyModalClose = () => this.setState({copyPractitionerModal: false, copyPractitioner: false});

    handleCreatePractitioner = () => {
        this.setState({createPractitionerModal: true});
    };

    onCreateModalClick = () => this.setState({createPractitionerModal: true, createPractitioner: false});
    onCreateModalClose = () => this.setState({createPractitionerModal: false, createPractitioner: false});

    handleShowPractitioner = (data) => {
        this.setState({showPractitioner: data.id});
    };

    onShowPractitionerClick = () => this.setState({showPractitioner: true, showPractitioner: false});
    onShowPractitionerClose = () => this.setState({showPractitioner: false, showPractitioner: false});
    
    render() {
        const practitionerData = this.state.practitionerData;
        const {showPractitioner} = this.state
        const auth = new Auth();
        return (
            <PractitionerListView
                practitionerData={this.state.practitionerData}
                isLoading={this.state.isLoading}
                totalPages={this.state.totalPages}
                copyPractitioner={this.state.copyPractitioner}
                copyPractitionerModal={this.state.copyPractitionerModal}
                onPractitionerAdded={this.onPractitionerAdded}
                onPageChange={this.handlePaginationChange}
                handleFilterChange={this.handleFilterChange}
                handleCopyPractitioner={this.handleCopyPractitioner}
                onCopyModalClose={this.onCopyModalClose}
                filter={this.state.filter}
            />
        );
    }
}

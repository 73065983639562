import React, {Component} from 'react'
import LoginBox from '../auth/LoginBox2'

export class Home extends Component {
    constructor(props){
        super(props);
    }

    render(){
        return (
            <div className="login-form">
                <LoginBox status={this.props.match.params.status}/>
            </div>
        )
    }

}
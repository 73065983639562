import React from 'react'
import {ApiClientFactory} from "../site/api/ApiClientFactory";
import {DisplayNotification} from "./Notifications";
import _ from 'lodash';


/**
 * Create a new API endpoint
 */
export class ApiEndpointResolver {
    constructor(endPoint) {
        this.endPoint = endPoint;
    }

    resolveURL = (params) => {
        const template = require('url-template');
        const endpoint = template.parse(this.endPoint);
        return endpoint.expand(params);
    }
}

export class AuthenticatedApiEndPoint {
    constructor(token, endPointUrl, endPointParams) {
        this.endPointUrl = endPointUrl;
        this.endPointParams = endPointParams;
        this.endPoint = new ApiEndpointResolver(this.endPointUrl).resolveURL(this.endPointParams);
        this.api = ApiClientFactory.createAuthenticatedApi(token);
    }

    /**
     *
     * @param params
     * @returns {*}
     */
    get = (params, axiosRequestConfig = {}) => {
        return this.api.get(this.endPoint, params, axiosRequestConfig);
    }

    /**
     *
     * @returns {*}
     */
    head = () => {
        return this.api.head(this.endPoint);
    }


    /**
     *
     * @returns {*}
     */
    delete = (params = {},  axiosRequestConfig = {}) => {
        return this.api.delete(this.endPoint, params, axiosRequestConfig);
    }

    /**
     *
     * @param params
     * @param headers
     * @returns {*}
     */
    post = (data, axiosRequestConfig = {}) => {
        console.log(axiosRequestConfig)
        return this.api.post(this.endPoint, data, axiosRequestConfig);
    }

    /**
     *
     * @param params
     * @returns {*}
     */
    patch = (data, axiosRequestConfig = {}) => {
        return this.api.patch(this.endPoint, data, axiosRequestConfig);
    }

    /**
     *
     * @param params
     * @returns {*}
     */
    put = (data, axiosRequestConfig = {}) => {
        return this.api.put(this.endPoint, data, axiosRequestConfig);
    }

    createOrUpdate = (params, create = true) => {
        if(create){
            return this.post(params);
        }else{
            return this.put(params);
        }
    }
}

export function FormApiError (apiResponse, genericMsg) {
    let msg = 'Server error';

    if (genericMsg) {
        msg = genericMsg;
    }
    console.log(apiResponse)
    if(apiResponse.status == 404){
        msg = "That resource does not exist.";
    }else {
        msg = _.get(apiResponse, 'data.message', msg)
        if (Array.isArray(_.get(apiResponse, "data.errors"))) {
            for (let i = 0; i < apiResponse.data.errors.length; i++) {
                msg += "\n" + apiResponse.data.errors[i];
            }
        }
    }

    DisplayNotification('error', 'Sorry, there has been an error', msg);
};

export function ApiError (apiResponse, genericMsg) {
    let msg = 'Server error';

    if (genericMsg) {
        msg = genericMsg;
    }

    if(apiResponse.status == 404){
        msg = "That resource does not exist.";
    }else {
        msg = _.get(apiResponse, 'data.message', msg)
    }

    DisplayNotification('error', 'Sorry, there has been an error', msg);
};
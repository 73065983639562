import React, {Component} from 'react'
import {PractitionerEP} from "../../config/ApiEndpoints";
import {Auth} from '../../helpers/Auth.js';
import {Confirm} from 'semantic-ui-react'
import {PropTypes} from 'prop-types';
import {ApiError, AuthenticatedApiEndPoint} from "../../helpers/ApiEndPoint";

export class PractitionerDeleter extends Component {
    constructor(props) {
        super(props);
    }

    handleDeletePractitioner = () => {
        this.setState({isLoading: true});
        const api = new AuthenticatedApiEndPoint(new Auth().getJWT(),PractitionerEP, {practitionerid: this.props.practitionerId})
        api.delete()
            .then(response => {
                if (response.ok) {
                    this.props.onDeletePractitioner();
                }else{
                    ApiError(response, "Could not delete this practitioner")
                }
            }).finally(() => {
            this.setState({isLoading: false});
        });
    };


    render() {
        return (<Confirm style={{height: 200}}
                         open={this.props.open}
                         header='Delete this practitioner?'
                         onCancel={this.props.onCancel}
                         onConfirm={this.handleDeletePractitioner}
        />);
    }

}

PractitionerDeleter.propTypes = {
    open: PropTypes.bool.isRequired,
    practitionerId: PropTypes.number.isRequired,
    onCancel: PropTypes.func.isRequired,
    onDeletePractitioner: PropTypes.func.isRequired
};
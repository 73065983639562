import React from 'react';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import EventWindow from '../calendar/EventWindow';
import moment from 'moment';
import {Auth} from '../../helpers/Auth';
import _ from 'lodash';
import PropTypes from 'prop-types';

export default class AppointmentCalendar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      appointments: [],
      showEventWindow: false,
      ctx: null,
      currentSelect: null,
    };
  }

  componentWillMount() {

  }

  onDateChange = (viewData) => {
    this.props.onDateChange(viewData);
  };

  handleDateSelect = (selectInfo) => {
    let ctx = this.createContextFromEvent(selectInfo.jsEvent);
    this.setState({showEventWindow: true, ctx: ctx, currentSelect: selectInfo});
  };

  createContextFromEvent = (e) => {
    const left = e.clientX;
    const top = e.clientY;
    const right = left + 1;
    const bottom = top + 1;

    return {
      getBoundingClientRect: () => ({
        left,
        top,
        right,
        bottom,

        height: 0,
        width: 0,
      }),
    };
  };

  onEventSave = (data) => {
    this.state.currentSelect.view.calendar.addEvent({
      title: data.title,
      start: data.starts.toISOString(),
      end: data.ends.toISOString(),
      // allDay: selectInfo.allDay
    });

    this.setState({showEventWindow: false, currentSelect: null, ctx: null});

  };

  render() {
    let appointments = this.props.appointments;
    let practice = (new Auth().getUser().getCurrentPractice());
    return (
        <React.Fragment>
          {
            this.state.showEventWindow &&
            <EventWindow
                start={moment(this.state.currentSelect.start).toISOString()}
                end={moment(this.state.currentSelect.end).toISOString()}
                context={this.state.ctx}
                calendar={this.state.currentSelect.view.calendar}
                side={this.state.currentSelect.jsEvent.clientX < 400
                    ? 'right'
                    : 'left'}
                onSave={this.onEventSave}
                onCancel={() => this.setState({showEventWindow: false})}
                practice={practice.id}
            />
          }

          <FullCalendar

              plugins={[
                dayGridPlugin,
                timeGridPlugin,
                interactionPlugin,
                listPlugin]}
              initialView={
                _.get(this.props, 'initialView', 'dayGridMonth')
              }
              headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: _.get(this.props, 'views',
                    'dayGridMonth,timeGridWeek,timeGridDay,listMonth'),
              }}
              editable={true}
              selectable={true}
              selectMirror={true}
              dayMaxEvents={100}
              datesSet={(viewData) => this.onDateChange(viewData)}
              events={appointments}
              eventMinHeight={18}
              expandRows={false}
              eventOverlap={false}
              select={this.handleDateSelect}
              eventContent={{html: '<i>some html</i>'}}
          />
        </React.Fragment>
    );
  }

}

AppointmentCalendar.propTypes = {
  views: PropTypes.string,
  initialView: PropTypes.string,
};
import React, {Component} from 'react';
import {Auth} from '../helpers/Auth.js';
import {Modal, Pagination} from 'semantic-ui-react';
import {PractitionerEdit} from '../components/practitioners/PractitionerEdit';
import {PractitionerCopy} from '../components/practitioners/PractitionerCopy';
import {PractitionerCreate} from '../components/practitioners/PractitionerCreate';
import {PractitionerDeleter} from '../components/practitioners/PractitionerDeleter';
import {
  Badge,
  Button,
  Col,
  Container,
  OverlayTrigger,
  Row,
  Spinner,
  Table,
} from 'react-bootstrap';
import SearchBar from '../components/helpers/searchbox/SearchBar';
import {
  copyRecord,
  deleteRecord,
  editDetails,
  newPractitioner,
  showAppointments,
  showProfile,
  userStatus,
  userVerified,
} from '../components/helpers/popovers/PopOvers';
import AwesomeDebouncePromise from 'awesome-debounce-promise';

export class PractitionerListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      PractitionerData: [],
    };
    this.filterDebouncer = AwesomeDebouncePromise(this.loadUsers, 200);
  }

  render() {

    const practitionerData = this.props.practitionerData;
    const auth = new Auth();
    return (

        <Container fluid
                   className="p-2 min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
          <Row>
            <Col lg={8} xs={4} md={8}>
              <h1 className="text-2xl p-2">Practitioners</h1>
            </Col>
            <Col lg={4} xs={4} md={4}>
              <OverlayTrigger
                  placement="top"
                  delay={{show: 250, hide: 400}}
                  overlay={newPractitioner}
              >
                <div class="float-right">
                  <Button onClick={() => this.props.handleCreatePractitioner()}
                          variant="secondary">
                    New Practitioner
                  </Button>
                </div>
              </OverlayTrigger>
            </Col>

          </Row>
          <Row>
            {this.props.isLoading === true &&
            <Col lg="auto" xs={2} md={4}>

              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>

            </Col>
            }
            <Col lg={8} xs={12} md={12}>
              {this.props.totalPages > 1 &&
              <Pagination
                  defaultActivePage={1}
                  totalPages={this.props.totalPages}
                  onPageChange={this.props.onPageChange}
                  pointing
                  secondary
              />
              }
            </Col>
          </Row>
          <Row>
            <Col lg={12} xs="auto" md="auto">
              <Table responsive
                     className="table-auto w-full overflow-auto  m-1 divide-y divide-purple-400">
                <thead className="bg-gray-50">
                <tr class="">
                  <th
                      scope="col"
                      className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >practitioner
                  </th>
                  <th
                      scope="col"
                      className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >Accreditations
                  </th>
                  <th
                      scope="col"
                      className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >Practice
                  </th>
                  <th
                      scope="col"
                      className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >User Status
                  </th>
                  <th colspan="3"
                      scope="col"
                      className="px-2 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >Actions
                  </th>
                </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                <tr>
                  <td>
                    <SearchBar
                        handleFilterChange={this.props.handleFilterChange}
                        filter={this.state.filter}
                    />
                  </td>
                </tr>
                {this.props.practitionerData.map((data) => {
                  return <tr key={data.id}>
                    <td className="px-2 py-4 whitespace-nowrap">
                      <Container className="items-center">
                        <Row>
                          <Col>
                            <Row>
                              <Col
                                  className="p-1 text-sm font-medium text-gray-900 fas fa-user">
                                &nbsp;{data.user.firstname} {data.user.lastname}
                              </Col>
                            </Row>
                            <Row>
                              <Col
                                  className="p-1 text-sm text-gray-500 inline-flex fas fa-at">
                                &nbsp;{data.user.email}
                              </Col>
                            </Row>
                            <Row>
                              <Col
                                  className="p-1 text-sm text-gray-500 inline-flex fas fa-phone">
                                <span>&nbsp;{data.user.mobile}</span>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Container>
                    </td>
                    <td className="px-2 py-4 whitespace-nowrap">
                      <Container
                          className="text-sm font-medium text-gray-900 items-center">
                        AHPRA: &nbsp;{data.ahpra}
                        <br></br>
                        &nbsp;{data.accreditation}
                      </Container>
                    </td>
                    <td className="px-2 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="ml-0">
                          <div className="text-sm font-medium text-gray-900">

                          </div>
                          <div className="text-sm font-medium text-gray-900">

                          </div>
                        </div>
                      </div>
                    </td>


                    <td className="px-2 py-4 whitespace-nowrap">
                      <Container className="text-sm font-medium text-gray-900">
                        {data.user.verified > false &&
                        <OverlayTrigger
                            placement="top"
                            delay={{show: 250, hide: 400}}
                            overlay={userVerified}
                        >
                                                <span
                                                    className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-200 text-green-800">
                                                    Verified
                                                </span>
                        </OverlayTrigger>}

                        {data.user.verified < true &&
                        <OverlayTrigger
                            placement="top"
                            delay={{show: 250, hide: 400}}
                            overlay={userVerified}
                        >
                          <Badge bg="warning" text="dark">
                            Not Verified
                          </Badge>
                        </OverlayTrigger>}
                        <br></br>
                        {data.user.approved > false &&
                        <OverlayTrigger
                            placement="top"
                            delay={{show: 250, hide: 400}}
                            overlay={userStatus}
                        >
                          <Badge bg="Success" text="dark">
                            Approved
                          </Badge>
                        </OverlayTrigger>}

                        {data.user.approved < true &&
                        <OverlayTrigger
                            placement="top"
                            delay={{show: 250, hide: 400}}
                            overlay={userStatus}
                        >
                          <Badge bg="danger">
                            Not Approved
                          </Badge>
                        </OverlayTrigger>}
                      </Container>
                    </td>
                    <td className="px-1 py-4 whitespace-nowrap">
                      <Row>
                        <Col className="p-1">
                          <OverlayTrigger
                              placement="top"
                              delay={{show: 250, hide: 400}}
                              overlay={showAppointments}
                          >
                            <a onClick={() => this.props.handleBookPractitioner(
                                data)}
                               className="text-sm font-medium text-orange-600 cursor-pointer text-decoration-none  d-flex fas fa-calendar-alt">
                              <span>&nbsp;Appointments</span>
                            </a>
                          </OverlayTrigger>
                        </Col>
                        <Col className="p-1">
                          <OverlayTrigger
                              placement="top"
                              delay={{show: 250, hide: 400}}
                              overlay={deleteRecord}
                          >
                            <a onClick={() => this.props.handleDeletePractitioner(
                                data)}
                               className="text-sm font-medium text-red-800 cursor-pointer text-decoration-none  d-flex fas fa-trash">
                              <span>&nbsp;Delete Practitioner</span>
                            </a>
                          </OverlayTrigger>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="p-1">
                          <OverlayTrigger
                              placement="top"
                              delay={{show: 250, hide: 400}}
                              overlay={showProfile}
                          >
                            <a onClick={() => this.props.handleShowPractitioner(
                                data)}
                               className="text-sm font-medium text-green-900 cursor-pointer text-decoration-none  d-flex fas fa-user">
                              <span>&nbsp;Show Profile</span>
                            </a>
                          </OverlayTrigger>
                        </Col>
                        <Col className="p-1">
                          <OverlayTrigger
                              placement="top"
                              delay={{show: 250, hide: 400}}
                              overlay={copyRecord}
                          >
                            <a onClick={() => this.props.handleCopyPractitioner(
                                data)}
                               className="text-sm font-medium text-gray-900 cursor-pointer text-decoration-none d-flex fas fa-calendar-alt">
                              <span>&nbsp;Copy Practitioner</span>
                            </a>
                          </OverlayTrigger>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="p-1">
                          <OverlayTrigger
                              placement="top"
                              delay={{show: 250, hide: 400}}
                              overlay={editDetails}
                          >
                            <a onClick={() => this.props.handleEditPractitioner(
                                data)}
                               className="text-sm font-medium text-decoration-none text-bluegray-700 cursor-pointer d-flex fas fa-calendar-alt">
                              <span>&nbsp;Edit Profile</span>
                            </a>
                          </OverlayTrigger>
                        </Col>
                      </Row>
                    </td>
                  </tr>;
                })
                }
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col
                class="py-2 align-middle page-wrapper inline-block min-w-full sm:px-6 lg:px-8 overflow-auto border-b border-gray-200 sm:rounded-lg">
              {this.state.deletePractitioner &&
              <PractitionerDeleter
                  practitionerId={this.state.deletePractitioner}
                  open={Number.isInteger(this.state.deletePractitioner)}
                  onCancel={() => this.setState({deletePractitioner: null})}
                  onDeletePractitioner={this.props.onPractitionerDeleted}/>
              }

              {this.state.showPractitioner &&
              this.props.history.push('/practitionerP',
                  {practitionerId: this.state.showPractitioner})
                //<PractitionerProfile
                // practitionerId={this.state.showPractitioner}
                // open={(this.state.showPractitioner)} onCancel={() =>
                // this.setState({showPractitioner: null})}/>
              }

              {this.state.editPractitionerModal &&
              <Modal open={this.state.editPractitionerModal}
                     closeIcon
                     size="medium"
                  //style={{height: 400}}
                  //dimmer='blurring'
                  //style={{ marginBottom: 10, height: 400 }}
                     onClose={this.onModalClose}
              >
                <Modal.Header>Edit Practitioner</Modal.Header>
                <Modal.Content>
                  <Modal.Description>
                    <PractitionerEdit data={this.state.editPractitioner}
                                      onCancel={this.onModalClose}
                                      onPractitionerSaved={this.onPractitionerAdded}/>
                  </Modal.Description>
                </Modal.Content>
              </Modal>
              }

              {this.props.copyPractitionerModal &&
              <Modal open={this.props.copyPractitionerModal}
                     closeIcon
                     size="tiny"
                     style={{height: 400}}
                     onClose={this.props.onCopyModalClose}
              >
                <Modal.Header>Copy Practitioner</Modal.Header>
                <Modal.Content>
                  <Modal.Description>
                    <PractitionerCopy data={this.props.copyPractitioner}
                                      onCancel={this.props.onCopyModalClose}
                                      onPractitionerSaved={this.props.onPractitionerAdded}/>
                  </Modal.Description>
                </Modal.Content>
              </Modal>
              }

              {this.state.createPractitionerModal &&
              <Modal open={this.state.createPractitionerModal}
                     closeIcon
                     size="medium"
                     scrolling={true}
                     onClose={this.onCreateModalClose}
              >
                <Modal.Header>Create Practitioner</Modal.Header>
                <Modal.Content>
                  <Modal.Description>
                    <PractitionerCreate data={this.state.createPractitioner}
                                        onCancel={this.onCreateModalClose}
                                        onPractitionerSaved={this.onPractitionerAdded}/>
                  </Modal.Description>
                </Modal.Content>
              </Modal>
              }
            </Col>
          </Row>
        </Container>
    );
  }
}

import {AppointmentsEP} from '../../config/ApiEndpoints';
import {ApiError, AuthenticatedApiEndPoint} from '../../helpers/ApiEndPoint';
import {Auth} from '../../helpers/Auth';
import moment from 'moment';
import _ from 'lodash';

export default class AppointmentsApi {
  getAppointments = (practiceId, filters = {}, onSuccess, onFinal, onError) => {
    filters = {...filters, ...{'filter[practice_id]': practiceId}};

    const endPoint = new AuthenticatedApiEndPoint(new Auth().getJWT(),
        AppointmentsEP);
    endPoint.get(filters).then(response => {
      if (response.ok) {
        onSuccess(response.data.data, response.meta);
      }
      else {
        if (onError) {
          onError(response);
        }
        else {
          ApiError(response, 'Could not retrieve the selected appointments');
        }
      }
    }).finally(() => {
      if (onFinal) {
        onFinal();
      }
    });
  };
}

let mapAppointments = (appointments) => {
  return appointments.map(x => {
    let date = new moment(_.get(x, 'appointment_date'));
    let end_date = new moment(_.get(x, 'appointment_end_date'));
    return {
      title: _.get(x, 'clients[0].firstname') + ' ' +
          _.get(x, 'clients[0].lastname') + ' ' + _.get(x, 'title'),
      start: date.toDate(),
      end: end_date.toDate(),
    };
  });
};

export {mapAppointments};
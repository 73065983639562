import {CurrentUserDetailsEP, UserEP} from '../config/ApiEndpoints';
import {ApiClientFactory} from '../site/api/ApiClientFactory';
import {Auth} from './Auth';
import React from 'react';
import {ApiError} from './ApiEndPoint';

export class User {

  constructor(userId) {
    this.userId = userId;
  }

  getCurrentCompany = () => {
    return localStorage.getItem('currentCompany');
  };

  setCompany = (companyId) => {
    localStorage.setItem('currentCompany', companyId);
  };

  setCurrentPractice = (practiceId, practiceName) => {
    localStorage.setItem('currentPractice',
        JSON.stringify({id: practiceId, name: practiceName}));
  };

  getCurrentPractice = () => {
    return JSON.parse(localStorage.getItem('currentPractice'));
  };

  setUserData = (data) => {
    localStorage.setItem('userData', JSON.stringify(data));
  };

  fetchCurrentCompany = () => {
    const auth = new Auth();
    if (!auth.isClientLoggedIn()) {
      return Promise.reject();
    }
    const template = require('url-template');
    const urlTemplate = template.parse(UserEP + '?primary_company=1');
    const api = ApiClientFactory.createAuthenticatedApi(auth.getJWT());
    return api.get(urlTemplate.expand({userid: this.userId}));
  };

  fetchUserData = () => {
    const auth = new Auth();
    if (!auth.isClientLoggedIn()) {
      return Promise.reject();
    }
    const api = ApiClientFactory.createAuthenticatedApi(auth.getJWT());
    return api.get(CurrentUserDetailsEP);

  };

  getUserData = () => {
    if (localStorage.getItem('userData')) {
      return JSON.parse(localStorage.getItem('userData'));
    }
    else {
      this.fetchUserData().then(response => {
        if (response.ok && response.data.data) {
          this.setUserData(response.data.data);
          return JSON.parse(localStorage.getItem('userData'));
        }
        else {
          ApiError(response, 'There has been an error');
        }
      });
    }
  };

}
import React, {Component} from 'react';
import {Form, Header} from 'semantic-ui-react';
import 'react-datepicker/dist/react-datepicker.css';
import {Auth} from '../../helpers/Auth';
import {
  PracticePractitionersEP,
  PractitionersEP,
} from '../../config/ApiEndpoints';
import PropTypes from 'prop-types';
import {ApiError, AuthenticatedApiEndPoint} from '../../helpers/ApiEndPoint';

/**
 *
 */
export class PractitionerDropdown extends Component {

  constructor(props) {
    super(props);
    this.state = {
      practitioners: [], isLoading: false,
      selected: null,
    };
  }

  componentDidMount = () => {
    this.load();
  };

  load(searchQuery) {
    this.setState({isLoading: true});
    let endPoint = new AuthenticatedApiEndPoint(new Auth().getJWT(),
        PractitionersEP);
    if (this.props.practice) {
      endPoint = new AuthenticatedApiEndPoint(new Auth().getJWT(),
          PracticePractitionersEP, {practiceId: this.props.practice});
    }
    endPoint.get({'filter[name]': searchQuery, sort: 'fullname'}).
        then(response => {
          if (response.ok) {
            this.setState({practitioners: response.data.data});
          }
          else {
            ApiError(response, 'Could not retrieve practitioners');
          }
        }).
        finally(() => {
          this.setState({isLoading: false});
        });
  }

  findPractitioner = (id) => {
    return this.state.practitioners.find(item => item.id == id);
  };

  /**
   * If we've recieved a non-int id, (e.g My Unit Type) it's a manually entered
   * value this checks if a dropdown value is an id or not
   * @param value
   */
  isSelectionManual = (value) => {
    return isNaN(parseFloat(value)) || !isFinite(value);
  };

  /*handleChange = (e, {value}) => {
    this.props.onSelect(value);
  };*/

  handleInputChange = (e, {searchQuery}) => {
    this.load(searchQuery);
  };

  handleChange = (e, {value}) => {
    if (this.props.onSelect) {
      this.setState({selected: value});
      const practitioner = this.findPractitioner(value);
      this.props.onSelect(practitioner);
    }

  };

  clear = () => {
    this.setState({clear: true});
  };

  render() {
    let selected = this.state.selected;
    const options = this.state.practitioners.map((value, index) => {
      if (this.state.practitioners.length == 1) {
        selected = value.id;
      }
      else if (this.props.defaultSelection && this.props.defaultSelection ==
          value.id) {
        selected = value.id;
      }
      return ({
        key: value.id,
        text: value.user.firstname + ' ' + value.user.lastname,
        value: value.id,
      });
    });

    return (
        <Form.Dropdown
            options={options}
            onSearchChange={this.handleInputChange}
            search
            placeholder="Search for practitioner"
            selection
            onChange={this.handleChange}
            fluid
            style={{minWidth: '370px'}}
            name={this.props.name}
            value={selected}
            loading={this.state.isLoading}
        />

    );

  }
}

const formatOptionLabel = ({value, text, description}) => (
    <Header size="tiny" content={text} subheader={description}/>
);

PractitionerDropdown.propTypes = {
  onSelect: PropTypes.func.isRequired, // function that handles client dropdown
  practiceId: PropTypes.number,                                     // selection
  defaultSelection: PropTypes.any, //selected value of the client dropdown
  width: PropTypes.number,
  name: PropTypes.string.isRequired,
};

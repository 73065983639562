import React, {Component} from "react";
import {Label, Message} from 'semantic-ui-react'
import {Form} from 'formsy-semantic-ui-react'
import {Auth} from "../../helpers/Auth";
import {UsersEP} from "../../config/ApiEndpoints";
import PropTypes from 'prop-types';
import {AuthenticatedApiEndPoint} from "../../helpers/ApiEndPoint";
import {DisplayNotification} from "../../helpers/Notifications";


export class UserCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: undefined,
            firstname: undefined,
            lastname: undefined,
            isLoading: false,
            selectedOption: null,
            formErrors: {firstname: undefined, lastname: undefined},
        };
    }
    setSelectedOption(selectedOption){
        this.setState({ selectedOption: selectedOption });
        //this.setState({ selecte
    }

    changeHandler = event => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });
    };

    validate = () => {
        if (this.state.firstname.trim().length === 0) {
            this.setState({formErrors: {firstname: 'User Name is required'}});
            return false;
        }
        return true;
    };

    onValidSubmit = (formData) => {

        const onResponse = (response) => {
            if (response.ok) {
                this.setState({error: false, saved: true});
                if (this.props.onUserSaved) {
                    this.props.onUserSaved();
                }
                DisplayNotification("success", null, "User was saved successfully")
            } else {
                this.setState({saved: false, error: true, errors: response.data.errors});
            }
        };
            this.save(formData, onResponse);
    };

    save = (formData, onResponse) => {
        this.setState({isLoading: true});
        const endPoint2 = new AuthenticatedApiEndPoint(new Auth().getJWT(), UsersEP);
        endPoint2.post(formData)
            .then(onResponse).finally(() => {
            this.setState({isLoading: false});
        })
    };

    


    render() {
        const { selectedOption } = this.state;
        const UserTypeOptions = [
            { key: 'ADM', value: 'Admin', text: 'Admin' },
            { key: 'OAD', value: 'Office Admin', text: 'Office Admin' }
        ]
        let errors = "";
        if (this.state.errors) {
            errors += Object.keys(this.state.errors).map((error, index) => (
                this.state.errors[error][0]
            ));
        }
        return (
            <Form
                ref={ref => this.form = ref}
                onValidSubmit={this.onValidSubmit} success={this.state.saved === true} error={this.state.error === true}
                loading={this.state.isLoading === true}>
                
                <Form.Input required
                            fluid
                            type = "hidden"
                            value="1"
                            defaultValue="1"
                            name="approved"
                            //label="Practice_id"
                            placeholder="Approved"
                />
               <Form.Select required
                            fluid
                            defaultValue="false"
                            name="usertype"
                            //label="Practice_id"
                            placeholder="User Type"
                            options = {UserTypeOptions}
                />

                <Form.Input required
                            fluid
                            name="firstname"
                            label="User First Name"
                            placeholder="First Name"
                            validationErrors={{
                                isDefaultRequiredValue: 'User Name is Required',
                            }}
                            errorLabel={<Label color="red" pointing/>}

                />
                <Form.Input name="lastname"
                            fluid
                            label="User Last Name"
                            //validations="isNumeric"
                            //validationErrors={{
                              //  isNumeric: 'Customer ID must be numeric',
                            //}}
                            errorLabel={<Label color="red" pointing/>}
                            placeholder="Last Name"/>

                <Form.Input required
                            fluid
                            name="email"
                            label="User Email"
                            placeholder="Email"
                            validationErrors={{
                                isDefaultRequiredValue: 'User Email is Required',
                            }}
                            errorLabel={<Label color="red" pointing/>}

                />

                <Form.Input required
                            fluid
                            name="mobile"
                            label="Mobile"
                            placeholder="Mobile"
                            validationErrors={{
                                isDefaultRequiredValue: 'User Mobile is Required',
                            }}
                            errorLabel={<Label color="red" pointing/>}

                />

                <Form.Group widths={1}>
                    <Form.Button primary>Save</Form.Button>
                    <Form.Button type="button" onClick={this.props.onCancel}>Cancel</Form.Button>
                </Form.Group>
                <Message success header="Save Successful"/>
                <Message error header="There's been a problem" content={errors}/>
            </Form>);
    }

}

UserCreate.propTypes = {
    onUserSaved: PropTypes.func,
    onCancel: PropTypes.func.isRequired,
    userData: PropTypes.object
};
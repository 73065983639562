import React, {Component} from 'react';
import {Container, Header, Segment} from 'semantic-ui-react';
import AppointmentCalendar from './AppointmentCalendar';
import AppointmentsApi, {mapAppointments} from '../../api/psy/AppointmentsApi';
import {getSessionPracticeId} from '../../helpers/Session';

export class AppointmentsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appointments: [],
      isLoading: false,
    };
  }

  componentWillMount() {
    this.loadAppointments();
  }

  loadAppointments = (startDate, endDate) => {
    this.setState({isLoading: true});

    let filters = {};
    if (startDate) {
      filters = {'filter[between_start_date]': startDate + ',' + endDate};
    }

    new AppointmentsApi().getAppointments(getSessionPracticeId(),
        filters,
        (data) => this.setState({appointments: data}),
        () => {this.setState({isLoading: false});},
    );

  };

  render() {

    return (
        <Container>
          <Header as="h1">First Header</Header>
          <Segment loading={this.state.isLoading}>
            <AppointmentCalendar
                initialView={'listMonth'}
                views=""
                appointments={mapAppointments(this.state.appointments)}
                onDateChange={
                  (viewData) => this.loadAppointments(
                      viewData.start.toISOString(), viewData.end.toISOString())
                }/>
          </Segment>
        </Container>
    );

  }
}

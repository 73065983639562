import React, {Component} from 'react';
import {AppointmentsEP} from '../../config/ApiEndpoints';
import {Auth} from '../../helpers/Auth.js';
//import {Container, Header, Segment} from 'semantic-ui-react';
import {ApiError, AuthenticatedApiEndPoint} from '../../helpers/ApiEndPoint';
import SearchBar from '../../components/helpers/searchbox/SearchBar';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Pagination,
  Table,
} from '@mui/material/';

export class AppointmentsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appointments: [],
      isLoading: false,
    };
  }

  componentWillMount() {
    this.loadAppointments();
  }

  loadAppointments = (startDate, endDate) => {
    this.setState({isLoading: true});
    let endPointUrl = AppointmentsEP;

    if (startDate) {
      endPointUrl = endPointUrl + '?filter[between_start_date]=' + startDate +
          ',';
      if (endDate) {
        endPointUrl = endPointUrl + endDate;
      }
    }

    const endPoint = new AuthenticatedApiEndPoint(new Auth().getJWT(),
        endPointUrl);
    endPoint.get().then(response => {
      if (response.ok) {
        this.setState({appointments: response.data.data});
      }
      else {
        ApiError(response, 'Could not retrieve the selected appointments');
      }
    }).finally(() => {
      this.setState({isLoading: false});
    });
  };

  render() {
    const appointmentsData = this.state.appointments;
    const auth = new Auth();

    //const startDate = toString(this.state.appointments.appointment_date,
    // "dd/MM/yyyy", new Date()) const date2 =
    // (this.state.appointments.appointment_date) const date3 =
    // utcToZonedTime(date2, {timeZone: 'UTC'}) const startDate = format(new
    // Date(date3), 'dd MMM yyyy hh:mm')

    return (

        <Grid lg={12} md={12} xs={12}>
          <Grid item lg={8} className="inline-flex p-2">
            <h1 className="text-2xl">Appointments</h1>
            <span className="inline-flex" class="items-center px-4">
                        <a class="text-sm text-bluegray-800 px-1">Today &nbsp;&nbsp;| </a>
                        <a class="text-sm text-bluegray-800 px-1">Next Day &nbsp;&nbsp;| </a>
                        <a class="text-sm text-bluegray-800 px-1">This Week &nbsp;&nbsp;| </a>
                        <a class="text-sm text-bluegray-800 px-1">This Month &nbsp;&nbsp;| </a>
                        <a class="text-sm text-bluegray-800 px-1">This Year </a>
                    </span>
          </Grid>
          <Grid item lg={4} className="float-right py-2">
            <Button variant="contained" color="secondary"
                    onClick={() => this.handleCreateClient()}>
              New Appointment
            </Button>
          </Grid>
          <Grid item lg={12}>
            <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'no-wrap',
                  alignContent: 'stretch',
                  p: 1,
                  m: 1,
                  bgcolor: 'background.paper',
                }}
            >

              <Box lg={12} xs={12} md={12}>
                {this.state.totalPages > 1 &&
                <Pagination
                    count={this.state.totalPages}
                    page={this.state.activePage}
                    onChange={this.handlePaginationChange}
                    color="secondary"
                    showFirstButton showLastButton
                />
                }
              </Box>
            </Box>
          </Grid>
          <Grid item lg={12} style={{width: '100%', overflow: 'auto'}}>
            <Box
                sx={{
                  alignContent: 'stretch',
                  m: 1,
                  bgcolor: 'background.paper',
                }}
            >
              <Box lg={12} xs="auto" md="auto">
                <Table responsive
                       className="table-auto w-full overflow-auto  m-1 divide-y divide-purple-400">
                  <thead className="bg-gray-50">
                  <tr class="">
                    <th
                        scope="col"
                        className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >client
                    </th>
                    <th
                        scope="col"
                        className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >Dates
                    </th>
                    <th
                        scope="col"
                        className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >Practice
                    </th>
                    <th
                        scope="col"
                        className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >User Status
                    </th>
                    <th colspan="3"
                        scope="col"
                        className="px-2 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >Actions
                    </th>
                  </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                  <tr>
                    <td>
                      <SearchBar
                          handleFilterChange={this.handleFilterChange}
                          filter={this.state.filter}
                      />
                    </td>
                  </tr>

                  {this.state.isLoading === true &&
                  <Box lg="auto" xs={12} md={12}
                       className="w-full items-center">
                    <CircularProgress color="secondary"/>
                  </Box>
                  }

                  {appointmentsData.sort(
                      (a, b) => a.lastname > b.lastname ? 1 : -1).
                      map((data) => {
                        return <tr key={data.id}>

                          <td className="px-2 py-4 whitespace-nowrap">
                            <Grid className="items-center">
                              <Box
                                  sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                  }}
                                  className="p-1 text-sm font-medium text-gray-900 fas fa-user">
                                &nbsp; - {data.appointment_end_date}
                              </Box>
                              <Box
                                  sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                  }}
                                  className="p-1 text-sm text-gray-500 inline-flex fas fa-at">
                                &nbsp;{data.client_confirmation}
                              </Box>
                              <Box
                                  sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                  }}
                                  className="p-1 text-sm text-gray-500 inline-flex fas fa-phone">
                                <span>&nbsp;</span>
                              </Box>
                            </Grid>
                          </td>
                          <td className="px-2 py-4 whitespace-nowrap">
                            <Box
                                sx={{
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                }}
                                className="p-1 text-sm font-medium text-gray-900">

                            </Box>
                            <Box
                                sx={{
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                }}
                                className="p-1 text-sm text-gray-900">
                              Age:
                            </Box>
                          </td>
                          <td className="px-2 py-4 whitespace-nowrap">
                            <Box
                                sx={{
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                }}
                                className="p-1 text-sm font-medium text-gray-900 fas fa-hospital">
                              &nbsp;
                            </Box>
                            <Box
                                sx={{
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                }}
                                className="text-sm py-2 text-gray-500 inline-flex fas fa-stethoscope">
                              &nbsp; Dr. Joe Blogs
                            </Box>
                          </td>
                        </tr>;
                      })
                  }
                  </tbody>
                </Table>
              </Box>
            </Box>
          </Grid>
        </Grid>
    );

  }
}
import React, {Component} from 'react';
import {Label, Message} from 'semantic-ui-react';
import {Form} from 'formsy-semantic-ui-react';
import {Auth} from '../../helpers/Auth';
import {PracticesEP} from '../../config/ApiEndpoints';
import PropTypes from 'prop-types';
import {AuthenticatedApiEndPoint} from '../../helpers/ApiEndPoint';
import {DisplayNotification} from '../../helpers/Notifications';

export class PracticeCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: undefined,
      firstname: undefined,
      lastname: undefined,
      isLoading: false,
      selectedOption: null,
            formErrors: {firstname: undefined, lastname: undefined},
        };
    }
    setSelectedOption(selectedOption){
        this.setState({ selectedOption: selectedOption });
        //this.setState({ selecte
    }

    changeHandler = event => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });
    };

    validate = () => {
        if (this.state.firstname.trim().length === 0) {
            this.setState({formErrors: {firstname: 'Practice Name is required'}});
            return false;
        }
        return true;
    };

    onValidSubmit = (formData) => {

        const onResponse = (response) => {
            if (response.ok) {
                this.setState({error: false, saved: true});
                if (this.props.onPracticeSaved) {
                    this.props.onPracticeSaved();
                }
                DisplayNotification("success", null, "Practice was saved successfully")
            } else {
                this.setState({saved: false, error: true, errors: response.data.errors});
            }
        };
            this.save(formData, onResponse);
    };

    save = (formData, onResponse) => {
        this.setState({isLoading: true});
        const endPoint2 = new AuthenticatedApiEndPoint(new Auth().getJWT(), PracticesEP);
        endPoint2.post(formData)
            .then(onResponse).finally(() => {
            this.setState({isLoading: false});
        })
    };

    render() {
        const { selectedOption } = this.state;
        let errors = "";
        if (this.state.errors) {
            errors += Object.keys(this.state.errors).map((error, index) => (
                this.state.errors[error][0]
            ));
        }
        return (
            <Form
                ref={ref => this.form = ref}
                onValidSubmit={this.onValidSubmit} success={this.state.saved === true} error={this.state.error === true}
                loading={this.state.isLoading === true}>

              <Form.Input required
                          fluid
                          name="practice_name"
                          label="Practice Name"
                          placeholder="Practice Name"
                          validationErrors={{
                            isDefaultRequiredValue: 'Practice Name is Required',
                          }}
                          errorLabel={<Label color="red" pointing/>}

              />

              <Form.Input required
                          fluid
                          name="firstname"
                          label="Owner First Name"
                          placeholder="Owner First Name"
                          validationErrors={{
                            isDefaultRequiredValue: 'Owner Name is Required',
                          }}
                          errorLabel={<Label color="red" pointing/>}

              />
              <Form.Input required
                          fluid
                          name="lastname"
                          label="Owner Last Name"
                          placeholder="Owner Last Name"
                          validationErrors={{
                            isDefaultRequiredValue: 'Owner Name is Required',
                          }}
                          errorLabel={<Label color="red" pointing/>}

              />

              <Form.Input required
                          fluid
                          name="email"
                          label="Practice Email"
                          placeholder="Email"
                          validationErrors={{
                            isDefaultRequiredValue: 'Practice Email is Required',
                          }}
                          errorLabel={<Label color="red" pointing/>}

              />
              <Form.Input required
                          fluid
                          name="location"
                          label="Practice Location"
                          placeholder="Location Name"
                          validationErrors={{
                            isDefaultRequiredValue: 'Practice Location is required',
                          }}
                          errorLabel={<Label color="red" pointing/>}

              />

              <Form.Group widths={1}>
                    <Form.Button primary>Save</Form.Button>
                    <Form.Button type="button" onClick={this.props.onCancel}>Cancel</Form.Button>
                </Form.Group>
                <Message success header="Save Successful"/>
                <Message error header="There's been a problem" content={errors}/>
            </Form>);
    }

}

PracticeCreate.propTypes = {
    onPracticeSaved: PropTypes.func,
    onCancel: PropTypes.func.isRequired,
    practiceData: PropTypes.object
};
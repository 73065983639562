/*eslint-disable*/
import React, {useState} from 'react';
import {Auth} from '../../helpers/Auth.js';
import {ApiClientFactory} from '../../site/api/ApiClientFactory';
import {User} from '../../helpers/User';
import {
  AppBar,
  Box,
  Button,
  Grid,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import PopupState, {bindMenu, bindTrigger} from 'material-ui-popup-state';

const useStyles = makeStyles(theme => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function Sidebar() {
  const classes = useStyles();
  const [collapseShow, setCollapseShow] = React.useState('hidden');
  const [isClientLoggedIn, setIsClientLoggedIn] = useState(false);
  const [displayName, setDisplayName] = useState('');
  const [displayLastName, setDisplayLastName] = useState('');
  const [displayEmail, setDisplayEmail] = useState('');
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleLogout = (e) => {
    e.preventDefault();
    new Auth().logout(ApiClientFactory.createAuthenticatedApi());
    setIsClientLoggedIn(!isClientLoggedIn);
  };

  const auth = new Auth();
  if (auth.isClientLoggedIn()) {
    const user = new User(auth.getUserId());
    user.fetchUserData().then(response => {
      if (response.ok) {
        setDisplayName(response.data.firstname);
        setDisplayLastName(response.data.lastname);
        setDisplayEmail(response.data.email);
      }
    });
  }

  return (
      <AppBar position="static"
              color="transparent"
              elevation={0}>
        <Toolbar>
          <Grid container
                justifyContent="space-around"
                align="middle"
                style={{backgroundColor: '#fff'}}
          >
            <Grid xs={12} md={3} lg={3}
                  alignItems="stretch"
            >
              <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'no-wrap',
                    alignContent: 'stretch',
                    p: 1,
                    m: 1,
                    bgcolor: 'background.paper',
                  }}
              >
                <Box sx={{justifyContent: 'flex-start'}}>
                  <Typography variant="h4" className={classes.title}>
                    CLINICARE
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Button
                className="text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline"
                href="dashboard">
              Dashboard
            </Button>
            <PopupState variant="popover" popupId="demo-popup-menu">
              {(popupState) => (
                  <React.Fragment>
                    <Button
                        className="text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline"
                        {...bindTrigger(popupState)}>
                      My Account
                    </Button>
                    <Menu {...bindMenu(popupState)}>
                      <MenuItem a href="/profile">Profile</MenuItem>
                      <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </Menu>
                  </React.Fragment>
              )}
            </PopupState>
            <PopupState variant="popover" popupId="demo-popup-menu">
              {(popupState) => (
                  <React.Fragment>
                    <Button
                        className="text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline"
                        {...bindTrigger(popupState)}>
                      Clinical
                    </Button>
                    <Menu {...bindMenu(popupState)}>
                      <MenuItem
                          component="a"
                          href="/calendar">
                        Calendar
                      </MenuItem>
                      <MenuItem
                          component="a"
                          href="/appointments">
                        Appointments
                      </MenuItem>
                      <MenuItem
                          component="a"
                          href="/clients">
                        Clients
                      </MenuItem>
                      <MenuItem
                          component="a"
                          href="/practitioners">
                        Practitioners
                      </MenuItem>
                    </Menu>
                  </React.Fragment>
              )}
            </PopupState>
            <PopupState variant="popover" popupId="demo-popup-menu">
              {(popupState) => (
                  <React.Fragment>
                    <Button
                        className="text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline"
                        {...bindTrigger(popupState)}>
                      Organisations
                    </Button>
                    <Menu {...bindMenu(popupState)}>
                      <MenuItem
                          component="a"
                          href="/contacts">
                        Contacts & Organisations
                      </MenuItem>
                      <MenuItem
                          component="a"
                          href="/referrers">
                        Referrers
                      </MenuItem>
                    </Menu>
                  </React.Fragment>
              )}
            </PopupState>
            <PopupState variant="popover" popupId="demo-popup-menu">
              {(popupState) => (
                  <React.Fragment>
                    <Button
                        className="text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline"
                        {...bindTrigger(popupState)}>
                      Practice Settings
                    </Button>
                    <Menu {...bindMenu(popupState)}>
                      <MenuItem
                          component="a"
                          href="/practices">
                        Practices
                      </MenuItem>
                      <MenuItem
                          component="a"
                          href="/locations">
                        Locations
                      </MenuItem>
                      <MenuItem
                          component="a"
                          href="/users">
                        Users
                      </MenuItem>
                      <MenuItem
                          component="a"
                          href="/services">
                        Services
                      </MenuItem>
                    </Menu>
                  </React.Fragment>
              )}
            </PopupState>
            <Button
                className="text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline"
                onClick={handleLogout}>
              Logout
            </Button>
          </Grid>
        </Toolbar>
      </AppBar>
  );
}
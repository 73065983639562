import React from 'react';

import PracticeApi from '../../api/psy/PracticeApi';
import _ from 'lodash';
import {Auth} from '../../helpers/Auth';
import {Redirect} from 'react-router-dom';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import {
  Card,
  CardActionArea,
  CardContent,
  Container,
  Grid,
  Pagination,
  Typography,
} from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';

export class PracticeSelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {practices: [], meta: {}, loading: false, selected: false};
    this.filterDebouncer = AwesomeDebouncePromise(this.load, 400);

  }

  componentDidMount() {
    this.load();
  };

  load = (filters) => {
    filters = {...filters, ...{sort: 'practice_name'}};
    this.setState({loading: true});
    let practice = new PracticeApi().getPractices(filters,
        (data, meta) => {
          if (data.length == 1) { // If there's only one practice, auto select it for the user
            this.handleClick(data[0]);
          }
          else {
            this.setState({practices: data, meta: meta});
          }
        },
        null,
        () => this.setState({loading: false}),
    );
  };

  handleClick = (item) => {
    (new Auth()).getUser().setCurrentPractice(item.id, item.practice_name);
    this.setState({selected: true});
  };

  handlePageChange = (e, page) => {
    this.load({page: page});
  };

  handleSearch = (e, {value}) => {
    this.filterDebouncer({'filter[practice_name]': value});
  };

  render() {
    const items = this.state.practices.map((item) => {
      const locations = item.locations.map(
          (location) => _.get(location, 'location'));
      return {
        header: item.practice_name,
        description: locations.join('\n'),
        onClick: () => {this.handleClick(item);},
      };
    });
    let segmentText = 'Loading...';
    if (this.state.selected || this.state.practices.length == 1) {
      return <Redirect to="/dashboard"/>;
    }
    else if (this.state.practices.length > 1) {
      segmentText = 'Select the practice you wish to work in';
    }

    return (
        <React.Fragment>
          <Container>
            <Grid align="center">
              <BusinessIcon fontSize="large" className="text-white"/>
              <Typography variant="h3">
                {segmentText}
              </Typography>
            </Grid>
            <Grid container spacing={1} align="center">
              {items.map((object, i) => {
                return <Grid item>
                  <CardActionArea>
                    <Card onClick={object.onClick}>
                      <CardContent>
                        <Typography variant={'h6'}>
                          {object.header}
                        </Typography>
                        <Typography>{object.description}</Typography>
                      </CardContent>
                    </Card>
                  </CardActionArea>
                </Grid>;
              })}
            </Grid>
            <br/>
            <Grid item align="center" spacing={2} justify="center"
                  alignItems="center">
              {this.state.practices.length > 1 &&
              <Grid item xs={4}>
                <Pagination onChange={this.handlePageChange}
                            page={this.state.meta.current_page}
                            count={this.state.meta.last_page}
                            variant="outlined" shape="rounded"
                />
              </Grid>
              }
            </Grid>
          </Container>

        </React.Fragment>
    );

  }

}
import React from 'react';
import ReactDOM from 'react-dom';
//import './index.css';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

<script src="https://use.fontawesome.com/746713eec9.js"></script>;
<script type="text/javascript"
        src="https://api.addressfinder.io/assets/v3/widget.js" async></script>;
<link rel="stylesheet"
      href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"/>;
<link rel="stylesheet"
      href="https://fonts.googleapis.com/icon?family=Material+Icons"/>;

ReactDOM.render(
    <React.StrictMode>
      <App/>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { Component }from 'react'
import { Feed, Icon } from 'semantic-ui-react'
import Editor from '../../helpers/CkEditor/Editor'

class ClinicalNotes extends Component {
  render() {
    return (
      <div class="max-w p-4 float-left bg-white w-full rounded-lg shadow-xl">
       
          <Feed>
            <Feed.Event>
              <Feed.Label>
                <img src='https://react.semantic-ui.com/images/avatar/small/elliot.jpg' />
              </Feed.Label>
              <Feed.Content>
                <Feed.Summary>
                  <Feed.User>Dr Joe Bloggs</Feed.User> added a note
                  <Feed.Date>1 Hour Ago</Feed.Date>
                </Feed.Summary>
                <Feed.Meta>
                </Feed.Meta>
                <Feed.Extra text>
                "Lorem ipsum dolor sit amet, 
                consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                </Feed.Extra>
              </Feed.Content>
            </Feed.Event>

            <Feed.Event>
              <Feed.Label image='https://react.semantic-ui.com/images/avatar/small/elliot.jpg' />
              <Feed.Content>
                <Feed.Summary>
                  <a>Dr Joe Bloggs</a> added <a>2 new pathology results</a>
                  <Feed.Date>4 days ago</Feed.Date>
                </Feed.Summary>
                <Feed.Extra images>
                  <a>
                    <img src='https://react.semantic-ui.com/images/wireframe/image.png' />
                  </a>
                  <a>
                    <img src='https://react.semantic-ui.com/images/wireframe/image.png' />
                  </a>
                </Feed.Extra>
                <Feed.Meta>
                  <Feed.Like>
                  </Feed.Like>
                </Feed.Meta>
              </Feed.Content>
            </Feed.Event>

            <Feed.Event>
              <Feed.Label image='https://react.semantic-ui.com/images/avatar/small/elliot.jpg' />
              <Feed.Content>
                <Feed.Summary
                  date='2 Days Ago'
                  user='Jenny Hess'
                  content='add you as a friend'
                />
                <Feed.Meta>
                  <Feed.Like>
                  </Feed.Like>
                </Feed.Meta>
              </Feed.Content>
            </Feed.Event>

            <Feed.Event>
              <Feed.Label image='https://react.semantic-ui.com/images/avatar/small/elliot.jpg' />
              <Feed.Content>
                <Feed.Summary>
                  <a>Dr Joe Bloggs</a> Added Clincal 
                  <Feed.Date>3 days ago</Feed.Date>
                </Feed.Summary>
                <Feed.Extra text>
                  Ours is a life of constant reruns. We're always circling back to where
                  we'd we started, then starting all over again. Even if we don't run
                  extra laps that day, we surely will come back for more of the same
                  another day soon.
                </Feed.Extra>
                <Feed.Meta>
                  <Feed.Like>
                  </Feed.Like>
                </Feed.Meta>
              </Feed.Content>
            </Feed.Event>

            <Feed.Event>
              <Feed.Label image='https://react.semantic-ui.com/images/avatar/small/elliot.jpg' />
              <Feed.Content>
                <Feed.Summary>
                  <a>Justen Kitsune</a> added <a>2 new photos</a> of you
                  <Feed.Date>4 days ago</Feed.Date>
                </Feed.Summary>
                <Feed.Extra images>
                  <a>
                    <img src='https://react.semantic-ui.com/images/wireframe/image.png' />
                  </a>
                  <a>
                    <img src='https://react.semantic-ui.com/images/wireframe/image.png' />
                  </a>
                </Feed.Extra>
                <Feed.Meta>
                  <Feed.Like>
                  </Feed.Like>
                </Feed.Meta>
              </Feed.Content>
            </Feed.Event>
          </Feed>
        </div>
)
    }}

export default ClinicalNotes

//Auth
export const AuthenticateEP = "/auth/login" ;
export const TokenValidationEP = "/auth/validate-token" ;
export const RefreshTokenEP = "/auth/refresh" ;
export const LogoutEP = "/auth/logout" ;

// Company
export const CompaniesEP = "/companies" ;
export const CompanyEP = "/companies/{companyid}" ;
export const CompanyUsersEP = "/companies/{companyid}/users" ;
export const CompanyContactsEP = "/companies_id/contacts" ;
export const CompanyContactEP = "/companies_id/contacts/{companyid}" ;
export const CompanyAddressesEP = "/companies_id/addresses" ;
export const CompanyAddressEP = "/companies_id/addresses/{companyid}" ;


// Clients
export const ClientsEP = "/clients" ;
//export const ClientEP = "/clients/" ;
export const ClientEP = "/clients/{clientid}" ;
export const ClientUsersEP = "/clients/{clientid}/users" ;
export const ClientContactsEP = "/{clientid}/contacts" ;
export const ClientContactEP = "{clientid}/" ;
export const ClientAddressesEP = "/clients/{clientid}/addresses" ;
export const ClientAddressEP = "/client_id/addresses/{clientid}" ;

// Practitioners
export const PractitionersEP = "/practitioners" ;
export const PractitionerEP = "/practitioners/{practitionerId}" ;
export const PractitionerUsersEP = "/practitioners/{practitionerid}/users" ;
export const PractitionerContactsEP = "/practitioner_id/contacts" ;
export const PractitionerContactEP = "/practitioner_id/contacts/{practitionerid}" ;
export const PractitionerAddressesEP = "/practitioner_id/addresses" ;
export const PractitionerAddressEP = "/practitioner_id/addresses/{practitionerid}" ;

// Practices
export const PracticesEP = '/practices';
export const PracticeEP = '/practices/{practiceId}';
export const PracticeUsersEP = '/practices/{practiceid}/users';
export const PracticeContactsEP = '/practice_id/contacts';
export const PracticeContactEP = '/practice_id/contacts/{practiceid}';
export const PracticeAddressesEP = '/practice_id/addresses';
export const PracticeAddressEP = '/practice_id/addresses/{practiceid}';
export const PracticeProductsEP = 'practices/:id/products';
export const PracticeProductEP = '/practice_id/products/{productid}';
export const PracticePractitionersEP = '/practices/{practiceId}/practitioners';
export const PracticeClientsEP = '/practices/{practiceId}/clients';

// Locations
export const LocationsEP = 'practices/{practiceId}/locations';
export const LocationEP = '/location/{locationId}';
export const LocationUsersEP = '/locations/{locationid}/users';
export const LocationContactsEP = '/location_id/contacts';
export const LocationContactEP = '/location_id/contacts/{locationid}';
export const LocationAddressesEP = '/location_id/addresses';
export const LocationAddressEP = '/location_id/addresses/{locationid}';

// Rooms
export const RoomsEP = "/rooms" ;
export const RoomEP = "/room/{roomId}" ;
export const RoomUsersEP = "/rooms/{roomid}/users" ;
export const RoomContactsEP = "/room_id/contacts" ;
export const RoomContactEP = "/room_id/contacts/{roomid}" ;
export const RoomAddressesEP = "/room_id/addresses" ;
export const RoomAddressEP = "/room_id/addresses/{roomid}" ;

// User
export const UsersEP = "/users" ;
export const UserEP = "/users/{userid}" ;
export const CurrentUserEP = '/user';
export const CurrentUserDetailsEP = '/user/details';
export const UserUsersEP = '/users/{userid}/users';

// Verification
export const VerificationEP = "/verify" ;
export const verificationEP = "/send" ;

// Appointments
export const AppointmentsEP = "/appointments" ;

// Project
export const ProjectsEP = "/projects" ;
export const ProjectEP = "/projects/{projectid}" ;


import React, {Component} from 'react';
import {ClientsEP} from '../../config/ApiEndpoints';
import {Auth} from '../../helpers/Auth.js';
import {ClientEdit} from './ClientEdit';
import {ClientCopy} from './ClientCopy';
import {ClientCreate} from './ClientCreate';
import {ClientDeleter} from './ClientDeleter';
import {differenceInCalendarYears, parseISO} from 'date-fns';
import {format} from 'date-fns-tz';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import '../../styles/output.css';
import 'tippy.js/dist/tippy.css';
import SearchBar from '../../components/helpers/searchbox/SearchBar';
import {apiRequest} from '../../api/psy/ApiRequest';
import {getSessionPracticeId} from '../../helpers/Session';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Modal,
  Pagination,
  Table,
} from '@mui/material/';

export class ClientList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientData: [],
      isLoading: true,
      editClientModal: false,
      deleteClientModel: false,
      deleteClient: undefined,
      showClient: undefined,
      syncingClient: false,
      syncroniseClient: null,
      addressData: [],
      postalAddresses: [],
      physicalAddresses: [],
      maxPhysicalId: [],
      activePage: 1,
      totalPages: 1,
      lastname: undefined,
      sort: null,
      filter: null,
    };
    this.filterDebouncer = AwesomeDebouncePromise(this.loadClients, 200);
  }

  componentWillMount() {
    this.loadClients();
  }

  loadClients = () => {
    this.setState({isLoading: true});
    apiRequest(ClientsEP, getSessionPracticeId(), {
      ...(this.state.filter ? {} : {page: this.state.activePage}),
      sort: 'lastname',
      'filter[lastname]=': this.state.filter,
    }, (data, meta) => {
      this.setState({clientData: data, totalPages: meta.last_page});
    }, () => this.setState({isLoading: false}));
  };

  handlePaginationChange = (event, value) => {
    this.setState({activePage: value}, this.loadClients);
  };

  handleFilterChange = async (e) => {
    const {value} = e.target;
    this.setState({filter: value}, () => {
      this.filterDebouncer();
      //this.loadClients()
    });
  };

  onClientAdded = () => {
    this.onModalClose();
    this.loadClients();
  };

  onClientDeleted = (data) => {
    this.loadClients();
    this.setState({deleteClient: null});
  };

  onClientSyncronised = () => {
    this.setState({syncroniseClient: null});
  };

  handleDeleteClient = (data) => {
    this.setState({deleteClient: data.id});
  };

  handleSyncroniseClient = (data) => {
    this.setState({syncroniseClient: data.id});
  };

  handleEditClient = (data) => {
    this.setState({editClient: data, editClientModal: true});
  };

  onModalClick = () => this.setState(
      {editClientModal: true, editClient: false});
  onModalClose = () => this.setState(
      {editClientModal: false, editClient: false});

  handleCopyClient = (data) => {
    this.setState({copyClient: data, copyClientModal: true});
  };

  onCopyModalClick = () => this.setState(
      {copyClientModal: true, copyClient: false});
  onCopyModalClose = () => this.setState(
      {copyClientModal: false, copyClient: false});

  handleCreateClient = () => {
    this.setState({createClientModal: true});
  };

  onCreateModalClick = () => this.setState(
      {createClientModal: true, createClient: false});
  onCreateModalClose = () => this.setState(
      {createClientModal: false, createClient: false});

  handleShowClient = (data) => {
    this.setState({showClient: data.id});
  };

  onShowClientClick = () => this.setState(
      {showClient: true, showClient: false});
  onShowClientClose = () => this.setState(
      {showClient: false, showClient: false});

  render() {
    const clientData = this.state.clientData;
    const {showClient} = this.state;
    const auth = new Auth();
    return (
        <Grid lg={12} md={12} xs={12}>
          <Grid item lg={3} className="inline-flex">
            <h1 className="text-2xl p-2">Clients</h1>
          </Grid>

          <Grid item lg={9} className="float-right py-2">
            <Button variant="contained" color="secondary"
                    onClick={() => this.handleCreateClient()}
            >
              New Client
            </Button>
          </Grid>
          <Grid item lg={12}>
            <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'no-wrap',
                  alignContent: 'stretch',
                  p: 1,
                  m: 1,
                  bgcolor: 'background.paper',
                }}
            >

              <Box lg={12} xs={12} md={12}>
                {this.state.totalPages > 1 &&
                <Pagination
                    count={this.state.totalPages}
                    page={this.state.activePage}
                    onChange={this.handlePaginationChange}
                    color="secondary"
                    showFirstButton showLastButton
                />
                }
              </Box>
            </Box>
          </Grid>
          <Grid item lg={12} style={{width: '100%', overflow: 'auto'}}>
            <Box
                sx={{
                  alignContent: 'stretch',
                  m: 1,
                  bgcolor: 'background.paper',
                }}
            >
              <Box lg={12} xs="auto" md="auto">
                <Table responsive
                       className="table-auto w-full overflow-auto  m-1 divide-y divide-purple-400">
                  <thead className="bg-gray-50">
                  <tr class="">
                    <th
                        scope="col"
                        className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >client
                    </th>
                    <th
                      scope="col"
                      className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >Dates
                  </th>
                  <th
                      scope="col"
                      className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >Practice
                  </th>
                  <th
                      scope="col"
                      className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >User Status
                  </th>
                  <th colspan="3"
                      scope="col"
                      className="px-2 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >Actions
                  </th>
                </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                <tr>
                  <td>
                    <SearchBar
                        handleFilterChange={this.handleFilterChange}
                        filter={this.state.filter}
                    />
                  </td>
                </tr>
                {this.state.isLoading === true &&
                <Box lg="auto" xs={12} md={12} className="w-full items-center">
                  <CircularProgress color="secondary"/>
                </Box>
                }
                {clientData.sort((a, b) => a.lastname > b.lastname ? 1 : -1).
                    map((data) => {
                      const Dob = format(new Date(data.dob), 'dd MMM yyyy');
                      const date = (data.dob);
                      const date2 = parseISO(date, 'dd/MM/yyyy', new Date());
                      const age = differenceInCalendarYears(new Date(), date2);
                      return <tr key={data.id}>

                        <td className="px-2 py-4 whitespace-nowrap">
                          <Grid className="items-center">
                            <Box
                                sx={{
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                }}
                                className="p-1 text-sm font-medium text-gray-900 fas fa-user">
                              &nbsp;{data.firstname} {data.lastname}
                            </Box>
                            <Box
                                sx={{
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                }}

                                className="p-1 text-sm text-gray-500 inline-flex fas fa-at">
                              &nbsp;{data.user.email}
                            </Box>
                            <Box
                                sx={{
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                }}

                                className="p-1 text-sm text-gray-500 inline-flex fas fa-phone">
                              <span>&nbsp;{data.user.mobile}</span>
                            </Box>
                          </Grid>
                        </td>
                        <td className="px-2 py-4 whitespace-nowrap">
                          <Box
                              sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                              }}
                              className="p-1 text-sm font-medium text-gray-900">
                            DOB: &nbsp;{Dob}
                          </Box>
                          <Box
                              sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                              }}
                              className="p-1 text-sm text-gray-900">
                            Age: &nbsp;{age} yrs
                          </Box>
                        </td>
                        <td className="px-2 py-4 whitespace-nowrap">
                          <Box
                              sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                              }}
                              className="p-1 text-sm font-medium text-gray-900 fas fa-hospital">
                            &nbsp;{data.practice.practice_name}
                          </Box>
                          <Box
                              sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                              }}

                              className="text-sm py-2 text-gray-500 inline-flex fas fa-stethoscope">
                            &nbsp; Dr. Joe Blogs
                          </Box>
                        </td>
                        <td className="px-2 py-4 whitespace-nowrap">

                          {data.user.verified > false &&
                          <Box
                              sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                              }}
                              class="px-2 text-center text-xs items-center leading-5 font-semibold rounded-full bg-green-200 text-green-800">
                            Verified
                          </Box>
                          }
                          {data.user.verified < true &&
                          <Box
                              sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                              }}
                              class="px-2 text-center text-xs leading-5 font-semibold rounded-full bg-yellow-200 text-green-800">
                            Not verified
                          </Box>
                          }
                          {data.user.approved > false &&
                          <Box
                              sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                              }}
                              class="px-2 text-center text-xs leading-5 font-semibold rounded-full bg-green-200 text-green-800">
                            Approved
                          </Box>
                          }
                          {data.user.approved < true &&
                          <Box
                              sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                              }}
                              class="px-2 text-center text-xs leading-5 font-semibold rounded-full bg-red-200 text-green-800">
                            Not Approved
                          </Box>
                          }
                        </td>
                        <td className="px-1 py-4 whitespace-nowrap">
                          <Grid container xs={12} md={12} lg={12}>
                            <Grid xs={12} md={12} lg={6}>
                              <a onClick={() => this.handleBookClient(data)}
                                 className="text-sm font-medium text-orange-600 cursor-pointer text-decoration-none  d-flex fas fa-calendar-alt">
                                <span>&nbsp;Appointments</span>
                              </a>
                              <br></br
                              >
                              <a onClick={() => this.handleShowClient(data)}
                                 className="text-sm font-medium text-green-900 cursor-pointer text-decoration-none  d-flex fas fa-user">
                                <span>&nbsp;Show Profile</span>
                              </a>
                              <br></br
                              >
                              <a onClick={() => this.handleCopyClient(data)}
                                 className="text-sm font-medium text-gray-900 cursor-pointer text-decoration-none d-flex fas fa-copy">
                                <span>&nbsp;Copy Client</span>
                              </a>
                            </Grid>
                            <Grid xs={12} md={12} lg={6}
                            >
                              <a onClick={() => this.handleEditClient(data)}
                                 className="text-sm font-medium text-decoration-none text-bluegray-700 cursor-pointer d-flex fas fa-marker">
                                <span>&nbsp;Edit Profile</span>
                              </a>
                              <br></br
                              >
                              <a onClick={() => this.handleDeleteClient(data)}
                                 className="text-sm font-medium text-red-800 cursor-pointer text-decoration-none  d-flex fas fa-trash">
                                <span>&nbsp;Delete Client</span>
                              </a>
                            </Grid>
                          </Grid>
                        </td>
                      </tr>;
                    })
                }
                </tbody>
                </Table>
              </Box>
            </Box>
          </Grid>

          <Grid
              class="py-2 align-middle page-wrapper inline-block min-w-full sm:px-6 lg:px-8">


            {this.state.deleteClient &&
            <ClientDeleter clientId={this.state.deleteClient}
                           open={Number.isInteger(this.state.deleteClient)}
                           onCancel={() => this.setState(
                               {deleteClient: null})}
                           onDeleteClient={this.onClientDeleted}/>
            }

            {this.state.showClient &&
            this.props.history.push('/clientP',
                {clientId: this.state.showClient})
            }

            {this.state.editClientModal &&
            <>
              <Modal
                  open={this.state.editClientModal}
                  onClose={this.onModalClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
              >
                <Box
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      width: 800,
                      bgcolor: 'background.paper',
                      border: '2px solid #000',
                      boxShadow: 24,
                      p: 4,
                    }}>
                  <ClientEdit data={this.state.editClient}
                              onCancel={this.onModalClose}
                              onClientSaved={this.onClientAdded}/>
                </Box>
              </Modal>
            </>
            }

            {this.state.copyClientModal &&
            <>
              <Modal
                  open={this.state.copyClientModal}
                  onClose={this.onCopyModalClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
              >
                <Box
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      width: 800,
                      bgcolor: 'background.paper',
                      border: '2px solid #000',
                      boxShadow: 24,
                      p: 4,
                    }}>
                  <ClientCopy data={this.state.copyClient}
                              onCancel={this.onCopyModalClose}
                              onClientSaved={this.onClientAdded}/>
                </Box>
              </Modal>
            </>
            }

            {this.state.createClientModal &&
            <>
              <Modal
                  open={this.state.createClientModal}
                  onClose={this.onCreateModalClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
              >
                <Box
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      width: 800,
                      bgcolor: 'background.paper',
                      border: '2px solid #000',
                      boxShadow: 24,
                      p: 4,
                    }}>
                  <ClientCreate data={this.state.createClient}
                                onCancel={this.onCreateModalClose}
                                onClientSaved={this.onClientAdded}/>
                </Box>
              </Modal>
            </>
            }
          </Grid>
        </Grid>
    );
  }
}

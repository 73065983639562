import {generateTimes} from '../../helpers/DateHelper';
import moment from 'moment';
import {Divider, Header, Icon, Label, Popup, Segment} from 'semantic-ui-react';
import React from 'react';
import {Form} from 'formsy-semantic-ui-react';
import {ErrorLabel} from '../helpers/FormErrorLabel';
import {ClientsDropdown} from '../clients/ClientsDropdown';
import {LocationsDropdown} from '../practices/locations/LocationsDropdown';
import {ApiError, AuthenticatedApiEndPoint} from '../../helpers/ApiEndPoint';
import {Auth} from '../../helpers/Auth';
import {AppointmentsEP} from '../../config/ApiEndpoints';
import {DisplayNotification} from '../../helpers/Notifications';
import {PractitionerDropdown} from '../practitioners/PractitionerDropdown';
import _ from 'lodash';
import PropTypes from 'prop-types';

export default class EventWindow extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      title: null,
      starts: null,
      ends: null,
      repeats: false,
      repeatsEvery: null,
      repeatsResolution: null,
      notes: null,
      canSubmit: false,
      clients: [],
      location: null,
      practitioner: null,
      product: null,
      isOpen: true,
    };
  }

  componentDidMount = () => {
    if (this.props.start) {
      this.setState({starts: moment.utc(this.props.start)});
    }
    if (this.props.end) {
      this.setState({ends: moment.utc(this.props.end)});
    }
  };

  handleSave = (data) => {

    this.setState({isLoading: true});

    const endPoint = new AuthenticatedApiEndPoint(new Auth().getJWT(),
        AppointmentsEP);
    endPoint.post(data).then(response => {
      if (response.ok) {
        DisplayNotification('success', 'Save Successful',
            'Event saved');
      }
      else {
        ApiError(response, 'Could not event');
      }
    }).finally(() => {
      this.setState({isLoading: false});
    });
  };

  handleSubmit = (data) => {

    const sendData = {};
    sendData['title'] = this.state.title;
    sendData['clients'] = this.state.clients.map((item) => item.id);
    sendData['appointment_date'] = this.state.starts.toISOString();
    sendData['appointment_end_date'] = this.state.ends.toISOString();
    sendData['practitioner_id'] = this.state.practitioner.id;
    sendData['location_id'] = this.state.location.id;
    sendData['notes'] = this.state.notes;
    sendData['is_recurring'] = this.state.repeats;
    sendData['repeats_resolution'] = this.state.repeatsResolution;
    sendData['repeats_every'] = this.state.repeatsEvery;
    sendData['product_id'] = this.state.product.id;
    console.log(sendData);

    this.handleSave(sendData);
    const calendarApi = this.props.calendar;
    calendarApi.unselect(); // clear date selection
    this.props.onSave(this.state);

  };

  handleCancel = () => {
    this.props.onCancel();
  };

  handleChange = (e, {name, value, data}) => {
    this.setState({[name]: value});
  };

  handleTimeChange = (e, {name, value}) => {
    let date = moment(this.props.end);
    this.setState({
      [name]: moment(date.format('YYYYMMDD') + 'T' + value + 'Z',
          'YYYYMMD h:mm aZ'),
    });
  };

  handleRepeatingChange = (e, data) => {
    this.setState(
        {repeats: data.checked, repeatsEvery: '1', repeatsResolution: 'days'});
  };

  handleLocationChange = (value) => {
    this.setState({'location': value});
  };

  disableButton = () => {
    this.setState({canSubmit: false});
  };

  enableButton = () => {
    this.setState({canSubmit: true});
  };

  addClient = (client) => {
    const clients = this.state.clients;
    clients.push(client);
    this.setState({clients: clients});
  };

  removeClient = (client) => {
    let clients = this.state.clients;
    clients = clients.filter(function(value, index, arr) {
      return value.id != client.id;
    });
    this.setState({clients: clients});
  };

  getClients = () => {
    return <Label.Group color="blue">
      {this.state.clients.map((item, index) => {
        return <Label key={item.id} as="a">
          {item.firstname + ' ' + item.lastname}
          <Icon name="close" onClick={() => this.removeClient(item)}/>
        </Label>;
      })}
    </Label.Group>;
  };

  render() {
    const user = new Auth().getUser();
    const practitionerId = _.get(user, 'practitioner.id');
    const startTimeOptions = generateTimes(moment(this.props.start));
    const endTimeOptions = generateTimes(moment(this.props.end));
    return <Popup
        content={
          <Segment>
            <Form ref={ref => this.form = ref} onValidSubmit={this.handleSubmit}
                  onValid={this.enableButton} onInvalid={this.disableButton}>

              <Form.Input
                  label="Title"
                  placeholder="Add Title..."
                  name="title"
                  required
                  onChange={this.handleChange}
                  errorLabel={ErrorLabel}
                  validationErrors={{
                    isDefaultRequiredValue: 'Title is required',
                  }}

              />
              <Header as="h5">{this.props.start}</Header>
              <Form.Group>
                <Form.Dropdown
                    name="starts"
                    options={startTimeOptions}
                    placeholder="Starts at..."
                    search
                    defaultValue={moment(this.props.start).format('h:mm a')}
                    onChange={this.handleTimeChange}
                    required

                />
                <Icon disabled name="angle right"/>
                <Form.Dropdown
                    name="ends"
                    options={endTimeOptions}
                    placeholder="Ends at..."
                    search
                    defaultValue={moment(this.props.end).format('h:mm a')}
                    onChange={this.handleTimeChange}
                    required
                />
              </Form.Group>

              <Form.Group>
                <Form.Checkbox
                    name="repeats"
                    label="Repeats"
                    onChange={this.handleRepeatingChange}
                />

              </Form.Group>
              {this.state.repeats &&
              <Form.Group>
                <Label pointing="right">Every</Label>
                <Form.Input
                    name="repeatsEvery"
                    type="number"
                    min={1}
                    defaultValue={1}
                    onChange={this.handleChange}
                >
                </Form.Input>

                <Form.Select
                    name="repeatsResolution"
                    options={[
                      {text: 'days', value: 'days', selected: true},
                      {text: 'weeks', value: 'weeks'},
                      {value: 'months', text: 'months'},
                      {value: 'years', text: 'years'}]}
                    searchInput={{id: 'form-select-repeats'}}
                    onChange={this.handleChange}
                    defaultValue={'days'}
                    style={{width: '80px'}}
                />
              </Form.Group>}

              <PractitionerDropdown
                  practice={this.props.practice}
                  onSelect={(value) => this.setState({practitioner: value})}
                  name="practitioner" defaultSelection={practitionerId}/>

              <Divider horizontal>Clients</Divider>

              {this.getClients()}

              <ClientsDropdown practice={this.props.practice}
                               onClientSelect={(c) => this.addClient(c)}
                               name="clients" deselect/>

              <Divider horizontal>Location</Divider>

              <LocationsDropdown practice={this.props.practice}
                                 onSelect={this.handleLocationChange}
                                 name="location"/>

              <Form.TextArea
                  name="notes"
                  label="Notes"
                  placeholder=""
                  onChange={this.handleChange}
              />

              <Form.Button floated="right" secondary type="cancel"
                           onClick={this.handleCancel}>Close</Form.Button>

              <Form.Button floated="left" primary type="submit"
                           disabled={!this.state.canSubmit}
                           loading={this.state.isLoading}>Submit</Form.Button>

            </Form></Segment>
        }
        on="click"
        open={this.state.isOpen}
        context={this.props.context}
        pinned
        eventsEnabled={false}
        position={this.props.side + ' center'}
        size="tiny"
        offset={[0, 50]}
        style={{width: '400px'}}
    />;
  };

}

EventWindow.propTypes = {
  context: PropTypes.element.isRequired,
  practice: PropTypes.number.isRequired,
  start: PropTypes.string,
  end: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  side: PropTypes.string,

};
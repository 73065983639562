import config from "../../config/Config";
import { create } from "apisauce";
import {Auth} from "../../helpers/Auth";

/**
 * Factory for creating apisauce objects
 */
export class ApiClientFactory {
    static createBaseApi() {
        const api = create({
            baseURL: config.apiBaseUrl,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        return api;
    }

    static createAuthenticatedApi(bearerToken) {
        const api = this.createBaseApi();
        api.setHeader('Authorization', 'Bearer ' + bearerToken);
        api.axiosInstance.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (401 === error.response.status) {
                new Auth().logoutClient(() => {
                    window.location.href = "/login/expired";
                });
            } else {
                return Promise.reject(error);
            }
        });
        return api;
    }
}
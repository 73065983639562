import React, {Component} from "react";
import {Form} from 'formsy-semantic-ui-react'
import {Header, Pagination} from 'semantic-ui-react'
import {ApiError, AuthenticatedApiEndPoint} from "../../helpers/ApiEndPoint";
import {Auth} from "../../helpers/Auth";
import {PracticesEP} from "../../config/ApiEndpoints";
import {DisplayNotification} from "../../helpers/Notifications";
import PropTypes from 'prop-types';

export class PracticeDropdown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isPracticesLoading: false,
            practices: [],
            practices1: [],
            error: false,
            loading: false,
            selectedOption: null,
            activePage: null, 
            totalPages: 10
        }
    }

    /**
     *
     * @param e
     * @param value
     */
    handleChange = (e, {value}) => {
        this.setState({ value });
        if(this.props.onChange){
        this.props.onChange(value);
        }
    }
    componentDidMount = () => {
        
            this.loadPractices();
        }

    loadPractices = () => {
        for (let page = 1; this.state.activePage <= this.state.totalPages; this.state.activePage += 1) {
            this.setState({activePage: this.state.activePage})
            this.setState({isPracticesLoading: true})
            const endPoint = new AuthenticatedApiEndPoint(new Auth().getJWT(), PracticesEP);
        let practice = endPoint.get({
            ... (this.state.filter ? {} : {page: this.state.activePage}),
            })
            .then(response => {
            if (response.ok) {
                response.data.data.forEach(value => {
                    this.state.practices1.push({
                        id: value.id,
                        practice_name: value.practice_name,
                    })
                })
                    this.setState(
                        ({practices: this.state.practices1})
                    )
            } else {
                this.setState({error: true});
                ApiError(response, "Could not retrieve the practices for this practice");
            };
        }).finally(() => {
            this.setState({isPracticesLoading: false});
        });
    }};

    render() {
        const { selectedOption } = this.state;
        const { value } = this.state;
        return (
           
            
            <Form.Dropdown loading={this.state.isPracticesLoading} error={this.state.error} label={this.props.ddFieldLabel}
                           value={value}
                           onChange={this.handleChange}
                           label="Select a Practice"
                           name="practices"
                           search={(items, s) => {
                               return items.filter(el => el.text.toLowerCase().includes(s.toLowerCase()))
                           }}
                           selection
                           options={this.state.practices1.map((value, index) => {
                               return ({
                                   key: value.id,
                                   text: value.practice_name,
                                   value: value.id,
                                   content: <Header size="tiny" content={value.practice_name}/>
                               })
                               

                           })}
            >
               
           </Form.Dropdown>
          
            
        );
        
    }

}

PracticeDropdown.propTypes = {
    ddFieldName: PropTypes.string.isRequired,
    ddFieldLabel: PropTypes.string.isRequired,
    pmSelection: PropTypes.number
};


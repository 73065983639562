import {Auth} from './Auth';

let getSessionPractice = () => {
  return new Auth().getUser().getCurrentPractice();
};

let getSessionPracticeId = () => {
  return new Auth().getUser().getCurrentPractice().id;
};

export {getSessionPracticeId, getSessionPractice};